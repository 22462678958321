// could make these a bit more composable
export const WindRose90 = ({ directions }) => {
  const [N, E, S, W] = directions;
  return (
    <svg viewBox="0,0,600,600" font-family="sans-serif">
      <g transform="translate(300,300)">
        <g class="axes">
          <g class="axis" transform="rotate(-90)">
            <line
              x1="30"
              x2="224.91525423728814"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(0)">
            <line
              x1="30"
              x2="224.91525423728814"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(90)">
            <line
              x1="30"
              x2="224.91525423728814"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(180)">
            <line
              x1="30"
              x2="224.91525423728814"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
        </g>
        <g class="rings">
          <g fill="#f7fbff">
            <path
              d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,29.998500012499957,-0.29999500002499996A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-45)"
            />
            <path
              d="M33.89697760300261,0.2999950000249999A33.898305084745765,33.898305084745765,0,0,1,0.29999500002500484,33.89697760300261L0.29999500002500207,29.998500012499957A30,30,0,0,0,29.998500012499957,0.29999500002499996Z"
              transform="rotate(-45)"
            />
            <path
              d="M-0.29999500002499835,29.998500012499957A30,30,0,0,1,-29.998500012499957,0.29999500002499724A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
              transform="rotate(-45)"
            />
            <path
              d="M-41.69383600795194,-0.2999950000250015A41.69491525423729,41.69491525423729,0,0,1,-0.2999950000250143,-41.69383600795194L-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-29.998500012499957,-0.2999950000249899Z"
              transform="rotate(-45)"
            />
          </g>
          <g fill="#deebf7">
            <path
              d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,29.998500012499957,-0.29999500002499996A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-45)"
            />
            <path
              d="M49.49061619910946,0.29999500002499996A49.49152542372882,49.49152542372882,0,0,1,0.29999500002500096,49.49061619910946L0.29999500002500484,33.89697760300261A33.898305084745765,33.898305084745765,0,0,0,33.89697760300261,0.2999950000249999Z"
              transform="rotate(-45)"
            />
            <path
              d="M-0.29999500002499496,37.79541960746659A37.79661016949152,37.79661016949152,0,0,1,-37.79541960746659,0.2999950000250103L-29.998500012499957,0.29999500002499724A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
              transform="rotate(-45)"
            />
            <path
              d="M-57.287350111059936,-0.29999500002498475A57.28813559322034,57.28813559322034,0,0,1,-0.2999950000250277,-57.287350111059936L-0.2999950000250143,-41.69383600795194A41.69491525423729,41.69491525423729,0,0,0,-41.69383600795194,-0.2999950000250015Z"
              transform="rotate(-45)"
            />
          </g>
          <g fill="#c6dbef">
            <path
              d="M0.29999500002500795,-57.287350111059936A57.28813559322034,57.28813559322034,0,0,1,57.287350111059936,-0.29999500002499996L29.998500012499957,-0.29999500002499996A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-45)"
            />
            <path
              d="M84.57573913837416,0.29999500002499996A84.57627118644068,84.57627118644068,0,0,1,0.29999500002500346,84.57573913837416L0.29999500002500096,49.49061619910946A49.49152542372882,49.49152542372882,0,0,0,49.49061619910946,0.29999500002499996Z"
              transform="rotate(-45)"
            />
            <path
              d="M-0.2999950000250014,100.16904230081217A100.16949152542374,100.16949152542374,0,0,1,-100.16904230081217,0.2999950000249975L-37.79541960746659,0.2999950000250103A37.79661016949152,37.79661016949152,0,0,0,-0.29999500002499496,37.79541960746659Z"
              transform="rotate(-45)"
            />
            <path
              d="M-100.16904230081217,-0.299995000024973A100.16949152542374,100.16949152542374,0,0,1,-0.29999500002504814,-100.16904230081217L-0.2999950000250277,-57.287350111059936A57.28813559322034,57.28813559322034,0,0,0,-57.287350111059936,-0.29999500002498475Z"
              transform="rotate(-45)"
            />
          </g>
          <g fill="#9ecae1">
            <path
              d="M0.29999500002502044,-146.9488463238876A146.94915254237287,146.94915254237287,0,0,1,146.9488463238876,-0.29999500002499996L57.287350111059936,-0.29999500002499996A57.28813559322034,57.28813559322034,0,0,0,0.29999500002500795,-57.287350111059936Z"
              transform="rotate(-45)"
            />
            <path
              d="M107.9656849105156,0.29999500002499996A107.96610169491524,107.96610169491524,0,0,1,0.299995000025008,107.9656849105156L0.29999500002500346,84.57573913837416A84.57627118644068,84.57627118644068,0,0,0,84.57573913837416,0.29999500002499996Z"
              transform="rotate(-45)"
            />
            <path
              d="M-0.29999500002500246,146.9488463238876A146.94915254237287,146.94915254237287,0,0,1,-146.9488463238876,0.2999950000249968L-100.16904230081217,0.2999950000249975A100.16949152542374,100.16949152542374,0,0,0,-0.2999950000250014,100.16904230081217Z"
              transform="rotate(-45)"
            />
            <path
              d="M-178.13534061202463,-0.29999500002496593A178.13559322033896,178.13559322033896,0,0,1,-0.29999500002502044,-178.13534061202463L-0.29999500002504814,-100.16904230081217A100.16949152542374,100.16949152542374,0,0,0,-100.16904230081217,-0.299995000024973Z"
              transform="rotate(-45)"
            />
          </g>
          <g fill="#6baed6">
            <path
              d="M0.29999500002499985,-166.44040760829543A166.4406779661017,166.4406779661017,0,0,1,166.44040760829543,-0.29999500002499996L146.9488463238876,-0.29999500002499996A146.94915254237287,146.94915254237287,0,0,0,0.29999500002502044,-146.9488463238876Z"
              transform="rotate(-45)"
            />
            <path
              d="M115.76232315050362,0.29999500002499996A115.76271186440678,115.76271186440678,0,0,1,0.2999950000250011,115.76232315050362L0.299995000025008,107.9656849105156A107.96610169491524,107.96610169491524,0,0,0,107.9656849105156,0.29999500002499996Z"
              transform="rotate(-45)"
            />
            <path
              d="M-0.2999950000249946,162.54209603946205A162.54237288135593,162.54237288135593,0,0,1,-162.54209603946205,0.29999500002498836L-146.9488463238876,0.2999950000249968A146.94915254237287,146.94915254237287,0,0,0,-0.29999500002500246,146.9488463238876Z"
              transform="rotate(-45)"
            />
            <path
              d="M-213.22012794069667,-0.29999500002500473A213.22033898305082,213.22033898305082,0,0,1,-0.2999950000249753,-213.22012794069667L-0.29999500002502044,-178.13534061202463A178.13559322033896,178.13559322033896,0,0,0,-178.13534061202463,-0.29999500002496593Z"
              transform="rotate(-45)"
            />
          </g>
          <g fill="#4292c6">
            <path
              d="M0.29999500002499985,-166.44040760829543A166.4406779661017,166.4406779661017,0,0,1,166.44040760829543,-0.29999500002499996A166.4406779661017,166.4406779661017,0,0,0,0.29999500002499985,-166.44040760829543Z"
              transform="rotate(-45)"
            />
            <path
              d="M131.3555896337172,0.29999500002499996A131.35593220338984,131.35593220338984,0,0,1,0.2999950000250221,131.3555896337172L0.2999950000250011,115.76232315050362A115.76271186440678,115.76271186440678,0,0,0,115.76232315050362,0.29999500002499996Z"
              transform="rotate(-45)"
            />
            <path
              d="M-0.29999500002499346,174.23702987552815A174.23728813559322,174.23728813559322,0,0,1,-174.23702987552815,0.29999500002498675L-162.54209603946205,0.29999500002498836A162.54237288135593,162.54237288135593,0,0,0,-0.2999950000249946,162.54209603946205Z"
              transform="rotate(-45)"
            />
            <path
              d="M-217.1184368146531,-0.29999500002496254A217.11864406779662,217.11864406779662,0,0,1,-0.29999500002512547,-217.1184368146531L-0.2999950000249753,-213.22012794069667A213.22033898305082,213.22033898305082,0,0,0,-213.22012794069667,-0.29999500002500473Z"
              transform="rotate(-45)"
            />
          </g>
          <g fill="#2171b5">
            <path
              d="M0.29999500002499985,-166.44040760829543A166.4406779661017,166.4406779661017,0,0,1,166.44040760829543,-0.29999500002499996A166.4406779661017,166.4406779661017,0,0,0,0.29999500002499985,-166.44040760829543Z"
              transform="rotate(-45)"
            />
            <path
              d="M135.25390459204957,0.29999500002499996A135.25423728813558,135.25423728813558,0,0,1,0.29999500002502116,135.25390459204957L0.2999950000250221,131.3555896337172A131.35593220338984,131.35593220338984,0,0,0,131.3555896337172,0.29999500002499996Z"
              transform="rotate(-45)"
            />
            <path
              d="M-0.29999500002498214,189.83027142875852A189.83050847457628,189.83050847457628,0,0,1,-189.83027142875852,0.29999500002505913L-174.23702987552815,0.29999500002498675A174.23728813559322,174.23728813559322,0,0,0,-0.29999500002499346,174.23702987552815Z"
              transform="rotate(-45)"
            />
            <path
              d="M-232.71167104096727,-0.2999950000249639A232.71186440677965,232.71186440677965,0,0,1,-0.2999950000251384,-232.71167104096727L-0.29999500002512547,-217.1184368146531A217.11864406779662,217.11864406779662,0,0,0,-217.1184368146531,-0.29999500002496254Z"
              transform="rotate(-45)"
            />
          </g>
          <g fill="#08519c">
            <path
              d="M0.29999500002499985,-166.44040760829543A166.4406779661017,166.4406779661017,0,0,1,166.44040760829543,-0.29999500002499996A166.4406779661017,166.4406779661017,0,0,0,0.29999500002499985,-166.44040760829543Z"
              transform="rotate(-45)"
            />
            <path
              d="M135.25390459204957,0.29999500002499996A135.25423728813558,135.25423728813558,0,0,1,0.29999500002502116,135.25390459204957A135.25423728813558,135.25423728813558,0,0,0,135.25390459204957,0.29999500002499996Z"
              transform="rotate(-45)"
            />
            <path
              d="M-0.2999950000250011,209.32181892560354A209.3220338983051,209.3220338983051,0,0,1,-209.32181892560354,0.2999950000249932L-189.83027142875852,0.29999500002505913A189.83050847457628,189.83050847457628,0,0,0,-0.29999500002498214,189.83027142875852Z"
              transform="rotate(-45)"
            />
            <path
              d="M-244.406595547806,-0.29999500002492696A244.4067796610169,244.4067796610169,0,0,1,-0.2999950000250018,-244.406595547806L-0.2999950000251384,-232.71167104096727A232.71186440677965,232.71186440677965,0,0,0,-232.71167104096727,-0.2999950000249639Z"
              transform="rotate(-45)"
            />
          </g>
          <g fill="#08306b">
            <path
              d="M0.29999500002499985,-166.44040760829543A166.4406779661017,166.4406779661017,0,0,1,166.44040760829543,-0.29999500002499996A166.4406779661017,166.4406779661017,0,0,0,0.29999500002499985,-166.44040760829543Z"
              transform="rotate(-45)"
            />
            <path
              d="M135.25390459204957,0.29999500002499996A135.25423728813558,135.25423728813558,0,0,1,0.29999500002502116,135.25390459204957A135.25423728813558,135.25423728813558,0,0,0,135.25390459204957,0.29999500002499996Z"
              transform="rotate(-45)"
            />
            <path
              d="M-0.2999950000249704,213.22012794069667A213.22033898305082,213.22033898305082,0,0,1,-213.22012794069667,0.29999500002505697L-209.32181892560354,0.2999950000249932A209.3220338983051,209.3220338983051,0,0,0,-0.2999950000250011,209.32181892560354Z"
              transform="rotate(-45)"
            />
            <path
              d="M-259.9998269287885,-0.29999500002495366A260,260,0,0,1,-0.29999500002503326,-259.9998269287885L-0.2999950000250018,-244.406595547806A244.4067796610169,244.4067796610169,0,0,0,-244.406595547806,-0.29999500002492696Z"
              transform="rotate(-45)"
            />
          </g>
        </g>
        <g class="direction-labels" font-weight="500" font-size="28">
          <g text-anchor="middle" transform="rotate(-90) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{N}</text>
          </g>
          <g text-anchor="middle" transform="rotate(0) translate(280,0)">
            <text transform="rotate(-90)translate(0,6)">{E}</text>
          </g>
          <g text-anchor="middle" transform="rotate(90) translate(280,0)">
            <text transform="rotate(-90)translate(0,6)">{S}</text>
          </g>
          <g text-anchor="middle" transform="rotate(180) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{W}</text>
          </g>
        </g>
        <g class="yAxis">
          <circle fill="none" stroke="gray" stroke-dasharray="4,4" r="30" />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="68.98305084745762"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="107.96610169491524"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="146.94915254237287"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="185.9322033898305"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="224.91525423728814"
          />
        </g>
      </g>
    </svg>
  );
};

export const WindRose45 = ({ directions }) => {
  const [N, NE, E, SE, S, SW, W, NW] = directions;
  return (
    <svg
      viewBox="0,0,600,600"
      font-family="sans-serif"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(300,300)">
        <g class="axes">
          <g class="axis" transform="rotate(-90)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(-45)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(0)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(45)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(90)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(135)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(180)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(225)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
        </g>
        <g class="rings">
          <g fill="#f7fbff">
            <path
              d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,21.000014285423717,-21.424271283103188A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M21.424271283103188,-21.000014285423717A30,30,0,0,1,29.998500012499957,-0.29999500002499996A30,30,0,0,0,21.424271283103188,-21.000014285423717Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M29.998500012499957,0.29999500002499996A30,30,0,0,1,21.424271283103188,21.000014285423717A30,30,0,0,0,29.998500012499957,0.29999500002499996Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M25.517827171233435,25.942084168912906A36.388888888888886,36.388888888888886,0,0,1,0.2999950000250014,36.38765226787599L0.29999500002500207,29.998500012499957A30,30,0,0,0,21.000014285423717,21.424271283103188Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002499835,29.998500012499957A30,30,0,0,1,-21.000014285423717,21.424271283103185A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-30.459841425645436,30.035584427965972A42.77777777777777,42.77777777777777,0,0,1,-42.77672585185661,0.29999500002500185L-29.998500012499957,0.29999500002499724A30,30,0,0,0,-21.42427128310318,21.000014285423724Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-36.38765226787599,-0.2999950000249947A36.388888888888886,36.388888888888886,0,0,1,-25.942084168912913,-25.51782717123343L-21.424271283103188,-21.000014285423717A30,30,0,0,0,-29.998500012499957,-0.2999950000249899Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-21.000014285423724,-21.42427128310318A30,30,0,0,1,-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-21.000014285423724,-21.42427128310318Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#deebf7">
            <path
              d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,21.000014285423717,-21.424271283103188A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M25.942084168912906,-25.517827171233435A36.388888888888886,36.388888888888886,0,0,1,36.38765226787599,-0.2999950000249999L29.998500012499957,-0.29999500002499996A30,30,0,0,0,21.424271283103188,-21.000014285423717Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M49.16575143441897,0.2999950000249999A49.166666666666664,49.166666666666664,0,0,1,34.97756474024961,34.55330774257014L21.424271283103188,21.000014285423717A30,30,0,0,0,29.998500012499957,0.29999500002499996Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M30.035584427965965,30.459841425645436A42.77777777777777,42.77777777777777,0,0,1,0.29999500002499924,42.77672585185661L0.2999950000250014,36.38765226787599A36.388888888888886,36.388888888888886,0,0,0,25.517827171233435,25.942084168912906Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002499696,36.38765226787599A36.388888888888886,36.388888888888886,0,0,1,-25.517827171233435,25.94208416891291L-21.000014285423717,21.424271283103185A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-39.49526582318272,39.07100882550326A55.55555555555555,55.55555555555555,0,0,1,-55.554745576650596,0.2999950000249971L-42.77672585185661,0.29999500002500185A42.77777777777777,42.77777777777777,0,0,0,-30.459841425645436,30.035584427965972Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-49.16575143441897,-0.29999500002499346A49.166666666666664,49.166666666666664,0,0,1,-34.97756474024962,-34.55330774257014L-25.942084168912913,-25.51782717123343A36.388888888888886,36.388888888888886,0,0,0,-36.38765226787599,-0.2999950000249947Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-21.000014285423724,-21.42427128310318A30,30,0,0,1,-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-21.000014285423724,-21.42427128310318Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#c6dbef">
            <path
              d="M0.2999950000250028,-61.94371800700072A61.94444444444445,61.94444444444445,0,0,1,43.588694555817725,-44.01295155349719L21.000014285423717,-21.424271283103188A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M48.530626237513296,-48.106369239833825A68.33333333333333,68.33333333333333,0,0,1,68.33267481552588,-0.2999950000249999L36.38765226787599,-0.2999950000249999A36.388888888888886,36.388888888888886,0,0,0,25.942084168912906,-25.517827171233435Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M68.33267481552588,0.2999950000249999A68.33333333333333,68.33333333333333,0,0,1,48.530626237513296,48.106369239833825L34.97756474024961,34.55330774257014A49.166666666666664,49.166666666666664,0,0,0,49.16575143441897,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M43.588694555817725,44.01295155349719A61.94444444444445,61.94444444444445,0,0,1,0.2999950000250028,61.94371800700072L0.29999500002499924,42.77672585185661A42.77777777777777,42.77777777777777,0,0,0,30.035584427965965,30.459841425645436Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.2999950000249946,81.1105563331862A81.1111111111111,81.1111111111111,0,0,1,-57.14169591016969,57.565952907849166L-25.517827171233435,25.94208416891291A36.388888888888886,36.388888888888886,0,0,0,-0.29999500002499696,36.38765226787599Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-80.15419703904972,79.72994004137027A113.05555555555554,113.05555555555554,0,0,1,-113.05515753372451,0.29999500002501L-55.554745576650596,0.2999950000249971A55.55555555555555,55.55555555555555,0,0,0,-39.49526582318272,39.07100882550326Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-113.05515753372451,-0.2999950000249823A113.05555555555554,113.05555555555554,0,0,1,-80.15419703904973,-79.72994004137026L-34.97756474024962,-34.55330774257014A49.166666666666664,49.166666666666664,0,0,0,-49.16575143441897,-0.29999500002499346Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-25.51782717123344,-25.9420841689129A36.388888888888886,36.388888888888886,0,0,1,-0.29999500002500584,-36.38765226787599L-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-21.000014285423724,-21.42427128310318Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#9ecae1">
            <path
              d="M0.29999500002499907,-151.3885916507988A151.38888888888889,151.38888888888889,0,0,1,106.83577125172124,-107.26002824940072L43.588694555817725,-44.01295155349719A61.94444444444445,61.94444444444445,0,0,0,0.2999950000250028,-61.94371800700072Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M62.08360820879964,-61.65935121112017A87.5,87.5,0,0,1,87.49948572991705,-0.29999500002499996L68.33267481552588,-0.2999950000249999A68.33333333333333,68.33333333333333,0,0,0,48.530626237513296,-48.106369239833825Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M100.27732903826939,0.29999500002499996A100.27777777777779,100.27777777777779,0,0,1,71.11890786107472,70.69465086339524L48.530626237513296,48.106369239833825A68.33333333333333,68.33333333333333,0,0,0,68.33267481552588,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M48.10636923983383,48.530626237513296A68.33333333333333,68.33333333333333,0,0,1,0.29999500002500906,68.33267481552588L0.2999950000250028,61.94371800700072A61.94444444444445,61.94444444444445,0,0,0,43.588694555817725,44.01295155349719Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.2999950000249909,119.44406771222228A119.44444444444446,119.44444444444446,0,0,1,-84.24758175297775,84.67183875065727L-57.14169591016969,57.565952907849166A81.1111111111111,81.1111111111111,0,0,0,-0.2999950000249946,81.1105563331862Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-120.81293184165364,120.38867484397424A170.55555555555557,170.55555555555557,0,0,1,-170.555291720498,0.29999500002498325L-113.05515753372451,0.29999500002501A113.05555555555554,113.05555555555554,0,0,0,-80.15419703904972,79.72994004137027Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-202.49977778506315,-0.29999500002500945A202.5,202.5,0,0,1,-143.40109455942692,-142.97683756174737L-80.15419703904973,-79.72994004137026A113.05555555555554,113.05555555555554,0,0,0,-113.05515753372451,-0.2999950000249823Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-66.17700261382844,-66.60125961150788A93.8888888888889,93.8888888888889,0,0,1,-0.2999950000250567,-93.88840961370092L-0.29999500002500584,-36.38765226787599A36.388888888888886,36.388888888888886,0,0,0,-25.51782717123344,-25.9420841689129Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#6baed6">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706L106.83577125172124,-107.26002824940072A151.38888888888889,151.38888888888889,0,0,0,0.29999500002499907,-151.3885916507988Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996L87.49948572991705,-0.29999500002499996A87.5,87.5,0,0,0,62.08360820879964,-61.65935121112017Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568L71.11890786107472,70.69465086339524A100.27777777777779,100.27777777777779,0,0,0,100.27732903826939,0.29999500002499996Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M52.62403571104174,53.04829270872121A74.72222222222223,74.72222222222223,0,0,1,0.2999950000250119,74.72162000938631L0.29999500002500906,68.33267481552588A68.33333333333333,68.33333333333333,0,0,0,48.10636923983383,48.530626237513296Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002498225,132.22188189699415A132.2222222222222,132.2222222222222,0,0,1,-93.28286081177163,93.70711780945112L-84.24758175297775,84.67183875065727A119.44444444444446,119.44444444444446,0,0,0,-0.2999950000249909,119.44406771222228Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-152.436357201005,152.0121002033256A215.2777777777778,215.2777777777778,0,0,1,-215.27756875238592,0.29999500002499807L-170.555291720498,0.29999500002498325A170.55555555555557,170.55555555555557,0,0,0,-120.81293184165364,120.38867484397424Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-215.27756875238592,-0.2999950000249454A215.2777777777778,215.2777777777778,0,0,1,-152.436357201005,-152.01210020332553L-143.40109455942692,-142.97683756174737A202.5,202.5,0,0,0,-202.49977778506315,-0.29999500002500945Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228L-0.2999950000250567,-93.88840961370092A93.8888888888889,93.8888888888889,0,0,0,-66.17700261382844,-66.60125961150788Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#4292c6">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706A164.166,164.166,0,0,0,0.2999950000250216,-164.16639256389965Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996A93.8888888888889,93.8888888888889,0,0,0,66.6012596115079,-66.17700261382842Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568A106.66666666666666,106.66666666666666,0,0,0,106.66624480489475,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M75.21229652640568,75.63655352408516A106.66666666666666,106.66666666666666,0,0,1,0.2999950000250094,106.66624480489475L0.2999950000250119,74.72162000938631A74.72222222222223,74.72222222222223,0,0,0,52.62403571104174,53.04829270872121Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.2999950000249976,144.999689665185A145,145,0,0,1,-102.31813533335755,102.742392331037L-93.28286081177163,93.70711780945112A132.2222222222222,132.2222222222222,0,0,0,-0.29999500002498225,132.22188189699415Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-152.436357201005,152.0121002033256A215.2777777777778,215.2777777777778,0,0,1,-215.27756875238592,0.29999500002499807A215.2777777777778,215.2777777777778,0,0,0,-152.436357201005,152.0121002033256Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-221.66646366582177,-0.29999500002495977A221.666,221.666,0,0,1,-156.95398811858377,-156.52973112090427L-152.436357201005,-152.01210020332553A215.2777777777778,215.2777777777778,0,0,0,-215.27756875238592,-0.2999950000249454Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,-84.24758175297778,-84.67183875065726Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#2171b5">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706A164.166,164.166,0,0,0,0.2999950000250216,-164.16639256389965Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996A93.8888888888889,93.8888888888889,0,0,0,66.6012596115079,-66.17700261382842Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568A106.66666666666666,106.66666666666666,0,0,0,106.66624480489475,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M79.72994004137027,80.15419703904972A113.05555555555554,113.05555555555554,0,0,1,0.29999500002500307,113.05515753372451L0.2999950000250094,106.66624480489475A106.66666666666666,106.66666666666666,0,0,0,75.21229652640568,75.63655352408516Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.2999950000249898,170.555291720498A170.55555555555557,170.55555555555557,0,0,1,-120.38867484397421,120.81293184165366L-102.31813533335755,102.742392331037A145,145,0,0,0,-0.2999950000249976,144.999689665185Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-165.9892492572003,165.5649922595209A234.44444444444443,234.44444444444443,0,0,1,-234.44425250763592,0.299995000025007L-215.27756875238592,0.29999500002499807A215.2777777777778,215.2777777777778,0,0,0,-152.436357201005,152.0121002033256Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-228.05535824170644,-0.2999950000249815A228.05555555555557,228.05555555555557,0,0,1,-161.47161879747776,-161.04736179979824L-156.95398811858377,-156.52973112090427A221.666,221.666,0,0,0,-221.66646366582177,-0.29999500002495977Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,-84.24758175297778,-84.67183875065726Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#08519c">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706A164.166,164.166,0,0,0,0.2999950000250216,-164.16639256389965Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996A93.8888888888889,93.8888888888889,0,0,0,66.6012596115079,-66.17700261382842Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568A106.66666666666666,106.66666666666666,0,0,0,106.66624480489475,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M84.24758175297778,84.67183875065724A119.44444444444446,119.44444444444446,0,0,1,0.2999950000250055,119.44406771222228L0.29999500002500307,113.05515753372451A113.05555555555554,113.05555555555554,0,0,0,79.72994004137027,80.15419703904972Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002499097,196.11088165686914A196.11111111111111,196.11111111111111,0,0,1,-138.45920578520494,138.8834627828844L-120.38867484397421,120.81293184165366A170.55555555555557,170.55555555555557,0,0,0,-0.2999950000249898,170.555291720498Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-175.0245095870993,174.60025258941982A247.2222222222222,247.2222222222222,0,0,1,-247.22204020575063,0.2999950000250554L-234.44425250763592,0.299995000025007A234.44444444444443,234.44444444444443,0,0,0,-165.9892492572003,165.5649922595209Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-234.44425250763592,-0.2999950000249496A234.44444444444443,234.44444444444443,0,0,1,-165.98924925720036,-165.56499225952086L-161.47161879747776,-161.04736179979824A228.05555555555557,228.05555555555557,0,0,0,-228.05535824170644,-0.2999950000249815Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,-84.24758175297778,-84.67183875065726Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#08306b">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706A164.166,164.166,0,0,0,0.2999950000250216,-164.16639256389965Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996A93.8888888888889,93.8888888888889,0,0,0,66.6012596115079,-66.17700261382842Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568A106.66666666666666,106.66666666666666,0,0,0,106.66624480489475,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M84.24758175297778,84.67183875065724A119.44444444444446,119.44444444444446,0,0,1,0.2999950000250055,119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,84.24758175297778,84.67183875065724Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002497687,202.49977778506315A202.5,202.5,0,0,1,-142.97683756174737,143.40109455942692L-138.45920578520494,138.8834627828844A196.11111111111111,196.11111111111111,0,0,0,-0.29999500002499097,196.11088165686914Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-184.05976922751475,183.63551222983537A260,260,0,0,1,-259.9998269287885,0.29999500002501733L-247.22204020575063,0.2999950000250554A247.2222222222222,247.2222222222222,0,0,0,-175.0245095870993,174.60025258941982Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-247.22204020575063,-0.2999950000249949A247.2222222222222,247.2222222222222,0,0,1,-175.02450958709932,-174.60025258941977L-165.98924925720036,-165.56499225952086A234.44444444444443,234.44444444444443,0,0,0,-234.44425250763592,-0.2999950000249496Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,-84.24758175297778,-84.67183875065726Z"
              transform="rotate(-22.5)"
            />
          </g>
        </g>
        <g class="direction-labels" font-weight="500" font-size="28">
          <g text-anchor="middle" transform="rotate(-90) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{N}</text>
          </g>
          <g text-anchor="middle" transform="rotate(-45) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{NE}</text>
          </g>
          <g text-anchor="middle" transform="rotate(0) translate(280,0)">
            <text transform="rotate(-90)translate(0,6)">{E}</text>
          </g>
          <g text-anchor="middle" transform="rotate(45) translate(280,0)">
            <text transform="rotate(-90)translate(0,6)">{SE}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(89.99999999999997) translate(280,0)"
          >
            <text transform="rotate(-90)translate(0,6)">{S}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(134.99999999999997) translate(280,0)"
          >
            <text transform="rotate(-90)translate(0,6)">{SW}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(180.00000000000006) translate(280,0)"
          >
            <text transform="rotate(90)translate(0,6)">{W}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(224.99999999999994) translate(280,0)"
          >
            <text transform="rotate(90)translate(0,6)">{NW}</text>
          </g>
        </g>
        <g class="yAxis">
          <circle fill="none" stroke="gray" stroke-dasharray="4,4" r="30" />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="93.8888888888889"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="157.7777777777778"
          />

          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="221.666"
          />
        </g>
      </g>
    </svg>
  );
};

export const WindRose22 = ({ directions }) => {
  const [
    N,
    NNE,
    NE,
    ENE,
    E,
    ESE,
    SE,
    SSE,
    S,
    SSW,
    SW,
    WSW,
    W,
    WNW,
    NW,
    NNW,
  ] = directions;
  return (
    <svg
      viewBox="0,0,600,600"
      font-family="sans-serif"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(300,300)">
        <g class="axes">
          <g class="axis" transform="rotate(-90)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(-67.5)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(-45)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(-22.5)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(0)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(22.5)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(45)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(67.5)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(90)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(112.5)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(135)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(157.5)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(180)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(202.5)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(225)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(247.5)">
            <line
              x1="30"
              x2="249.047619047619"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
        </g>
        <g class="rings">
          <g fill="#f7fbff">
            <path
              d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,11.202769710208853,-27.829803283890225A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M11.757088190966494,-27.600197051286358A30,30,0,0,1,21.000014285423717,-21.424271283103188A30,30,0,0,0,11.757088190966494,-27.600197051286358Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M21.424271283103188,-21.000014285423717A30,30,0,0,1,27.600197051286358,-11.757088190966492A30,30,0,0,0,21.424271283103188,-21.000014285423717Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M27.829803283890225,-11.202769710208852A30,30,0,0,1,29.998500012499957,-0.29999500002499996A30,30,0,0,0,27.829803283890225,-11.202769710208852Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M29.998500012499957,0.29999500002499996A30,30,0,0,1,27.829803283890225,11.202769710208852A30,30,0,0,0,29.998500012499957,0.29999500002499996Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M37.71924828022066,15.948536448339215A40.95238095238095,40.95238095238095,0,0,1,29.169057796236704,28.744800798557236L21.424271283103188,21.000014285423717A30,30,0,0,0,27.600197051286358,11.757088190966492Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M21.000014285423717,21.424271283103188A30,30,0,0,1,11.757088190966494,27.600197051286358A30,30,0,0,0,21.000014285423717,21.424271283103188Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M11.202769710208853,27.829803283890225A30,30,0,0,1,0.29999500002500207,29.998500012499957A30,30,0,0,0,11.202769710208853,27.829803283890225Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-0.29999500002499835,29.998500012499957A30,30,0,0,1,-11.20276971020885,27.829803283890225A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-11.75708819096649,27.600197051286358A30,30,0,0,1,-21.000014285423717,21.424271283103185A30,30,0,0,0,-11.75708819096649,27.600197051286358Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-29.169057796236707,28.74480079855723A40.95238095238095,40.95238095238095,0,0,1,-37.71924828022065,15.948536448339246L-27.600197051286354,11.757088190966503A30,30,0,0,0,-21.42427128310318,21.000014285423724Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-37.948854512824525,15.394217967581588A40.95238095238095,40.95238095238095,0,0,1,-40.95128213705762,0.29999500002501345L-29.998500012499957,0.29999500002499724A30,30,0,0,0,-27.829803283890215,11.202769710208873Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-40.95128213705762,-0.2999950000250034A40.95238095238095,40.95238095238095,0,0,1,-37.948854512824525,-15.394217967581579L-27.829803283890218,-11.202769710208868A30,30,0,0,0,-29.998500012499957,-0.2999950000249899Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-27.600197051286358,-11.757088190966495A30,30,0,0,1,-21.424271283103167,-21.000014285423735A30,30,0,0,0,-27.600197051286358,-11.757088190966495Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-21.000014285423724,-21.42427128310318A30,30,0,0,1,-11.757088190966504,-27.600197051286354A30,30,0,0,0,-21.000014285423724,-21.42427128310318Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-11.202769710208875,-27.829803283890215A30,30,0,0,1,-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-11.202769710208875,-27.829803283890215Z"
              transform="rotate(-11.25)"
            />
          </g>
          <g fill="#deebf7">
            <path
              d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,11.202769710208853,-27.829803283890225A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M11.757088190966494,-27.600197051286358A30,30,0,0,1,21.000014285423717,-21.424271283103188A30,30,0,0,0,11.757088190966494,-27.600197051286358Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M21.424271283103188,-21.000014285423717A30,30,0,0,1,27.600197051286358,-11.757088190966492A30,30,0,0,0,21.424271283103188,-21.000014285423717Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M37.948854512824525,-15.394217967581575A40.95238095238095,40.95238095238095,0,0,1,40.95128213705762,-0.29999500002499996L29.998500012499957,-0.29999500002499996A30,30,0,0,0,27.829803283890225,-11.202769710208852Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M62.85642696783858,0.29999500002499996A62.857142857142854,62.857142857142854,0,0,1,58.18656947867848,23.77695397787924L27.829803283890225,11.202769710208852A30,30,0,0,0,29.998500012499957,0.29999500002499996Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M37.71924828022066,15.948536448339215A40.95238095238095,40.95238095238095,0,0,1,29.169057796236704,28.744800798557236A40.95238095238095,40.95238095238095,0,0,0,37.71924828022066,15.948536448339215Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M28.744800798557236,29.169057796236704A40.95238095238095,40.95238095238095,0,0,1,15.948536448339217,37.71924828022066L11.757088190966494,27.600197051286358A30,30,0,0,0,21.000014285423717,21.424271283103188Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M15.394217967581577,37.948854512824525A40.95238095238095,40.95238095238095,0,0,1,0.2999950000250018,40.95128213705762L0.29999500002500207,29.998500012499957A30,30,0,0,0,11.202769710208853,27.829803283890225Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-0.29999500002499835,29.998500012499957A30,30,0,0,1,-11.20276971020885,27.829803283890225A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-11.75708819096649,27.600197051286358A30,30,0,0,1,-21.000014285423717,21.424271283103185A30,30,0,0,0,-11.75708819096649,27.600197051286358Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-29.169057796236707,28.74480079855723A40.95238095238095,40.95238095238095,0,0,1,-37.71924828022065,15.948536448339246A40.95238095238095,40.95238095238095,0,0,0,-29.169057796236707,28.74480079855723Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-68.30534821628544,27.968289365648605A73.8095238095238,73.8095238095238,0,0,1,-73.80891414990889,0.29999500002499596L-40.95128213705762,0.29999500002501345A40.95238095238095,40.95238095238095,0,0,0,-37.948854512824525,15.394217967581588Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-51.903894953943315,-0.2999950000250037A51.9047619047619,51.9047619047619,0,0,1,-48.06774932186601,-19.585601433713276L-37.948854512824525,-15.394217967581579A40.95238095238095,40.95238095238095,0,0,0,-40.95128213705762,-0.2999950000250034Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-27.600197051286358,-11.757088190966495A30,30,0,0,1,-21.424271283103167,-21.000014285423735A30,30,0,0,0,-27.600197051286358,-11.757088190966495Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-21.000014285423724,-21.42427128310318A30,30,0,0,1,-11.757088190966504,-27.600197051286354A30,30,0,0,0,-21.000014285423724,-21.42427128310318Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-11.202769710208875,-27.829803283890215A30,30,0,0,1,-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-11.202769710208875,-27.829803283890215Z"
              transform="rotate(-11.25)"
            />
          </g>
          <g fill="#c6dbef">
            <path
              d="M0.2999950000250023,-62.85642696783858A62.857142857142854,62.857142857142854,0,0,1,23.77695397787924,-58.18656947867848L11.202769710208853,-27.829803283890225A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M11.757088190966494,-27.600197051286358A30,30,0,0,1,21.000014285423717,-21.424271283103188A30,30,0,0,0,11.757088190966494,-27.600197051286358Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M52.40291220625604,-51.978655208576555A73.8095238095238,73.8095238095238,0,0,1,68.0757419836816,-28.522607846406192L27.600197051286358,-11.757088190966492A30,30,0,0,0,21.424271283103188,-21.000014285423717Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M58.18656947867848,-23.77695397787924A62.857142857142854,62.857142857142854,0,0,1,62.85642696783858,-0.29999500002499996L40.95128213705762,-0.29999500002499996A40.95238095238095,40.95238095238095,0,0,0,37.948854512824525,-15.394217967581575Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M84.76137387906223,0.29999500002499996A84.76190476190476,84.76190476190476,0,0,1,78.42410159070434,32.15961424764138L58.18656947867848,23.77695397787924A62.857142857142854,62.857142857142854,0,0,0,62.85642696783858,0.29999500002499996Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M47.83814308926215,20.139919914470905A51.9047619047619,51.9047619047619,0,0,1,36.91372459076727,36.4894675930878L29.169057796236704,28.744800798557236A40.95238095238095,40.95238095238095,0,0,0,37.71924828022066,15.948536448339215Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M44.234077251275906,44.65833424895537A62.857142857142854,62.857142857142854,0,0,1,24.331272458636878,57.956963246074615L15.948536448339217,37.71924828022066A40.95238095238095,40.95238095238095,0,0,0,28.744800798557236,29.169057796236704Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M15.394217967581577,37.948854512824525A40.95238095238095,40.95238095238095,0,0,1,0.2999950000250018,40.95128213705762A40.95238095238095,40.95238095238095,0,0,0,15.394217967581577,37.948854512824525Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-0.29999500002499635,106.66624480489475A106.66666666666666,106.66666666666666,0,0,1,-40.54224543905322,98.66156350138256L-11.20276971020885,27.829803283890225A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-41.09656391981086,98.4319572687787A106.66666666666666,106.66666666666666,0,0,1,-75.21229652640568,75.63655352408517L-21.000014285423717,21.424271283103185A30,30,0,0,0,-11.75708819096649,27.600197051286358Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-44.65833424895538,44.2340772512759A62.857142857142854,62.857142857142854,0,0,1,-57.956963246074594,24.331272458636924L-37.71924828022065,15.948536448339246A40.95238095238095,40.95238095238095,0,0,0,-29.169057796236707,28.74480079855723Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-108.7802803881374,44.73355520736083A117.6190476190476,117.6190476190476,0,0,1,-117.61866504008515,0.2999950000249977L-73.80891414990889,0.29999500002499596A73.8095238095238,73.8095238095238,0,0,0,-68.30534821628544,27.968289365648605Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-117.61866504008515,-0.2999950000249689A117.6190476190476,117.6190476190476,0,0,1,-108.7802803881374,-44.7335552073608L-48.06774932186601,-19.585601433713276A51.9047619047619,51.9047619047619,0,0,0,-51.903894953943315,-0.2999950000250037Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-27.600197051286358,-11.757088190966495A30,30,0,0,1,-21.424271283103167,-21.000014285423735A30,30,0,0,0,-27.600197051286358,-11.757088190966495Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-28.74480079855723,-29.169057796236707A40.95238095238095,40.95238095238095,0,0,1,-15.948536448339231,-37.71924828022066L-11.757088190966504,-27.600197051286354A30,30,0,0,0,-21.000014285423724,-21.42427128310318Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-19.58560143371329,-48.067749321866A51.9047619047619,51.9047619047619,0,0,1,-0.2999950000250196,-51.903894953943315L-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-11.202769710208875,-27.829803283890215Z"
              transform="rotate(-11.25)"
            />
          </g>
          <g fill="#9ecae1">
            <path
              d="M0.29999500002499263,-161.42829267656072A161.42857142857142,161.42857142857142,0,0,1,61.49877388192374,-149.255098688418L23.77695397787924,-58.18656947867848A62.857142857142854,62.857142857142854,0,0,0,0.2999950000250023,-62.85642696783858Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M24.331272458636878,-57.956963246074615A62.857142857142854,62.857142857142854,0,0,1,44.234077251275906,-44.65833424895537L21.000014285423717,-21.424271283103188A30,30,0,0,0,11.757088190966494,-27.600197051286358Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M67.89201654837731,-67.46775955069783A95.71428571428571,95.71428571428571,0,0,1,88.31323207620312,-36.905250711119756L68.0757419836816,-28.522607846406192A73.8095238095238,73.8095238095238,0,0,0,52.40291220625604,-51.978655208576555Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M58.18656947867848,-23.77695397787924A62.857142857142854,62.857142857142854,0,0,1,62.85642696783858,-0.29999500002499996A62.857142857142854,62.857142857142854,0,0,0,58.18656947867848,-23.77695397787924Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M128.5710785826187,0.29999500002499996A128.57142857142856,128.57142857142856,0,0,1,118.89899109168363,48.92486241449938L78.42410159070434,32.15961424764138A84.76190476190476,84.76190476190476,0,0,0,84.76137387906223,0.29999500002499996Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M57.956963246074615,24.331272458636874A62.857142857142854,62.857142857142854,0,0,1,44.65833424895538,44.234077251275906L36.91372459076727,36.4894675930878A51.9047619047619,51.9047619047619,0,0,0,47.83814308926215,20.139919914470905Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M51.978655208576555,52.40291220625603A73.8095238095238,73.8095238095238,0,0,1,28.522607846406203,68.07574198368158L24.331272458636878,57.956963246074615A62.857142857142854,62.857142857142854,0,0,0,44.234077251275906,44.65833424895537Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M19.585601433713272,48.06774932186602A51.9047619047619,51.9047619047619,0,0,1,0.29999500002500173,51.903894953943315L0.2999950000250018,40.95128213705762A40.95238095238095,40.95238095238095,0,0,0,15.394217967581577,37.948854512824525Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-0.29999500002497287,161.42829267656072A161.42857142857142,161.42857142857142,0,0,1,-61.49877388192372,149.255098688418L-40.54224543905322,98.66156350138256A106.66666666666666,106.66666666666666,0,0,0,-0.29999500002499635,106.66624480489475Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-53.67048614437211,128.78809083553898A139.52380952380952,139.52380952380952,0,0,1,-98.44587529972756,98.87013229740705L-75.21229652640568,75.63655352408517A106.66666666666666,106.66666666666666,0,0,0,-41.09656391981086,98.4319572687787Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-83.38108414279303,82.9568271451136A117.6190476190476,117.6190476190476,0,0,1,-108.55067415553354,45.28787368811844L-57.956963246074594,24.331272458636924A62.857142857142854,62.857142857142854,0,0,0,-44.65833424895538,44.2340772512759Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-149.25509868841797,61.49877388192382A161.42857142857142,161.42857142857142,0,0,1,-161.42829267656072,0.2999950000250025L-117.61866504008515,0.2999950000249977A117.6190476190476,117.6190476190476,0,0,0,-108.7802803881374,44.73355520736083Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-216.19026804753452,-0.2999950000249394A216.1904761904762,216.1904761904762,0,0,1,-199.84856689354783,-82.45527457998057L-108.7802803881374,-44.7335552073608A117.6190476190476,117.6190476190476,0,0,0,-117.61866504008515,-0.2999950000249689Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-68.07574198368158,-28.522607846406203A73.8095238095238,73.8095238095238,0,0,1,-52.402912206255984,-51.978655208576605L-21.424271283103167,-21.000014285423735A30,30,0,0,0,-27.600197051286358,-11.757088190966495Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-67.46775955069785,-67.89201654837728A95.71428571428571,95.71428571428571,0,0,1,-36.9052507111198,-88.31323207620311L-15.948536448339231,-37.71924828022066A40.95238095238095,40.95238095238095,0,0,0,-28.74480079855723,-29.169057796236707Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-40.54224543905328,-98.66156350138253A106.66666666666666,106.66666666666666,0,0,1,-0.2999950000250225,-106.66624480489475L-0.2999950000250196,-51.903894953943315A51.9047619047619,51.9047619047619,0,0,0,-19.58560143371329,-48.067749321866Z"
              transform="rotate(-11.25)"
            />
          </g>
          <g fill="#6baed6">
            <path
              d="M0.29999500002501783,-183.33308788680526A183.33333333333331,183.33333333333331,0,0,1,69.88137609823448,-169.49249064701422L61.49877388192374,-149.255098688418A161.42857142857142,161.42857142857142,0,0,0,0.29999500002499263,-161.42829267656072Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M24.331272458636878,-57.956963246074615A62.857142857142854,62.857142857142854,0,0,1,44.234077251275906,-44.65833424895537A62.857142857142854,62.857142857142854,0,0,0,24.331272458636878,-57.956963246074615Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M75.63655352408517,-75.21229652640568A106.66666666666666,106.66666666666666,0,0,1,98.4319572687787,-41.09656391981087L88.31323207620312,-36.905250711119756A95.71428571428571,95.71428571428571,0,0,0,67.89201654837731,-67.46775955069783Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M58.18656947867848,-23.77695397787924A62.857142857142854,62.857142857142854,0,0,1,62.85642696783858,-0.29999500002499996A62.857142857142854,62.857142857142854,0,0,0,58.18656947867848,-23.77695397787924Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M128.5710785826187,0.29999500002499996A128.57142857142856,128.57142857142856,0,0,1,118.89899109168363,48.92486241449938A128.57142857142856,128.57142857142856,0,0,0,128.5710785826187,0.29999500002499996Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M78.19449535810047,32.71393272839902A84.76190476190476,84.76190476190476,0,0,1,60.14747075141294,59.72321375373346L44.65833424895538,44.234077251275906A62.857142857142854,62.857142857142854,0,0,0,57.956963246074615,24.331272458636874Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M51.978655208576555,52.40291220625603A73.8095238095238,73.8095238095238,0,0,1,28.522607846406203,68.07574198368158A73.8095238095238,73.8095238095238,0,0,0,51.978655208576555,52.40291220625603Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M23.77695397787924,58.18656947867848A62.857142857142854,62.857142857142854,0,0,1,0.2999950000250023,62.85642696783858L0.29999500002500173,51.903894953943315A51.9047619047619,51.9047619047619,0,0,0,19.585601433713272,48.06774932186602Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-0.2999950000249846,172.3806913397325A172.38095238095238,172.38095238095238,0,0,1,-65.69007539497711,159.37379564522644L-61.49877388192372,149.255098688418A161.42857142857142,161.42857142857142,0,0,0,-0.29999500002497287,161.42829267656072Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-53.67048614437211,128.78809083553898A139.52380952380952,139.52380952380952,0,0,1,-98.44587529972756,98.87013229740705A139.52380952380952,139.52380952380952,0,0,0,-53.67048614437211,128.78809083553898Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-129.848198159469,129.4239411617896A183.33333333333331,183.33333333333331,0,0,1,-169.26288441441034,70.43569457899218L-108.55067415553354,45.28787368811844A117.6190476190476,117.6190476190476,0,0,0,-83.38108414279303,82.9568271451136Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-169.49249064701417,69.88137609823461A183.33333333333331,183.33333333333331,0,0,1,-183.33308788680526,0.29999500002498836L-161.42829267656072,0.2999950000250025A161.42857142857142,161.42857142857142,0,0,0,-149.25509868841797,61.49877388192382Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-216.19026804753452,-0.2999950000249394A216.1904761904762,216.1904761904762,0,0,1,-199.84856689354783,-82.45527457998057A216.1904761904762,216.1904761904762,0,0,0,-216.19026804753452,-0.2999950000249394Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-78.19449535810047,-32.713932728399044A84.76190476190476,84.76190476190476,0,0,1,-60.147470751412904,-59.7232137537335L-52.402912206255984,-51.978655208576605A73.8095238095238,73.8095238095238,0,0,0,-68.07574198368158,-28.522607846406203Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-90.70135303139844,-91.1256100290779A128.57142857142856,128.57142857142856,0,0,1,-49.47918089525712,-118.66938485907971L-36.9052507111198,-88.31323207620311A95.71428571428571,95.71428571428571,0,0,0,-67.46775955069785,-67.89201654837728Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-44.73355520736079,-108.78028038813741A117.6190476190476,117.6190476190476,0,0,1,-0.29999500002505713,-117.61866504008515L-0.2999950000250225,-106.66624480489475A106.66666666666666,106.66666666666666,0,0,0,-40.54224543905328,-98.66156350138253Z"
              transform="rotate(-11.25)"
            />
          </g>
          <g fill="#4292c6">
            <path
              d="M0.29999500002501783,-183.33308788680526A183.33333333333331,183.33333333333331,0,0,1,69.88137609823448,-169.49249064701422A183.33333333333331,183.33333333333331,0,0,0,0.29999500002501783,-183.33308788680526Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M24.331272458636878,-57.956963246074615A62.857142857142854,62.857142857142854,0,0,1,44.234077251275906,-44.65833424895537A62.857142857142854,62.857142857142854,0,0,0,24.331272458636878,-57.956963246074615Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M75.63655352408517,-75.21229652640568A106.66666666666666,106.66666666666666,0,0,1,98.4319572687787,-41.09656391981087A106.66666666666666,106.66666666666666,0,0,0,75.63655352408517,-75.21229652640568Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M58.18656947867848,-23.77695397787924A62.857142857142854,62.857142857142854,0,0,1,62.85642696783858,-0.29999500002499996A62.857142857142854,62.857142857142854,0,0,0,58.18656947867848,-23.77695397787924Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M128.5710785826187,0.29999500002499996A128.57142857142856,128.57142857142856,0,0,1,118.89899109168363,48.92486241449938A128.57142857142856,128.57142857142856,0,0,0,128.5710785826187,0.29999500002499996Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M108.55067415553356,45.287873688118395A117.6190476190476,117.6190476190476,0,0,1,83.38108414279304,82.95682714511358L60.14747075141294,59.72321375373346A84.76190476190476,84.76190476190476,0,0,0,78.19449535810047,32.71393272839902Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M59.723213753733475,60.14747075141293A84.76190476190476,84.76190476190476,0,0,1,32.71393272839903,78.19449535810047L28.522607846406203,68.07574198368158A73.8095238095238,73.8095238095238,0,0,0,51.978655208576555,52.40291220625603Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M32.15961424764138,78.42410159070434A84.76190476190476,84.76190476190476,0,0,1,0.2999950000250111,84.76137387906223L0.2999950000250023,62.85642696783858A62.857142857142854,62.857142857142854,0,0,0,23.77695397787924,58.18656947867848Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-0.2999950000249954,183.33308788680526A183.33333333333331,183.33333333333331,0,0,1,-69.88137609823445,169.49249064701422L-65.69007539497711,159.37379564522644A172.38095238095238,172.38095238095238,0,0,0,-0.2999950000249846,172.3806913397325Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-53.67048614437211,128.78809083553898A139.52380952380952,139.52380952380952,0,0,1,-98.44587529972756,98.87013229740705A139.52380952380952,139.52380952380952,0,0,0,-53.67048614437211,128.78809083553898Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-129.848198159469,129.4239411617896A183.33333333333331,183.33333333333331,0,0,1,-169.26288441441034,70.43569457899218A183.33333333333331,183.33333333333331,0,0,0,-129.848198159469,129.4239411617896Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-169.49249064701417,69.88137609823461A183.33333333333331,183.33333333333331,0,0,1,-183.33308788680526,0.29999500002498836A183.33333333333331,183.33333333333331,0,0,0,-169.49249064701417,69.88137609823461Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-227.14265903616686,-0.2999950000249489A227.1428571428571,227.1428571428571,0,0,1,-209.96725676000634,-86.64657315611487L-199.84856689354783,-82.45527457998057A216.1904761904762,216.1904761904762,0,0,0,-216.19026804753452,-0.2999950000249394Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-78.19449535810047,-32.713932728399044A84.76190476190476,84.76190476190476,0,0,1,-60.147470751412904,-59.7232137537335A84.76190476190476,84.76190476190476,0,0,0,-78.19449535810047,-32.713932728399044Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-90.70135303139844,-91.1256100290779A128.57142857142856,128.57142857142856,0,0,1,-49.47918089525712,-118.66938485907971A128.57142857142856,128.57142857142856,0,0,0,-90.70135303139844,-91.1256100290779Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-44.73355520736079,-108.78028038813741A117.6190476190476,117.6190476190476,0,0,1,-0.29999500002505713,-117.61866504008515A117.6190476190476,117.6190476190476,0,0,0,-44.73355520736079,-108.78028038813741Z"
              transform="rotate(-11.25)"
            />
          </g>
          <g fill="#2171b5">
            <path
              d="M0.29999500002501783,-183.33308788680526A183.33333333333331,183.33333333333331,0,0,1,69.88137609823448,-169.49249064701422A183.33333333333331,183.33333333333331,0,0,0,0.29999500002501783,-183.33308788680526Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M24.331272458636878,-57.956963246074615A62.857142857142854,62.857142857142854,0,0,1,44.234077251275906,-44.65833424895537A62.857142857142854,62.857142857142854,0,0,0,24.331272458636878,-57.956963246074615Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M75.63655352408517,-75.21229652640568A106.66666666666666,106.66666666666666,0,0,1,98.4319572687787,-41.09656391981087A106.66666666666666,106.66666666666666,0,0,0,75.63655352408517,-75.21229652640568Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M58.18656947867848,-23.77695397787924A62.857142857142854,62.857142857142854,0,0,1,62.85642696783858,-0.29999500002499996A62.857142857142854,62.857142857142854,0,0,0,58.18656947867848,-23.77695397787924Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M128.5710785826187,0.29999500002499996A128.57142857142856,128.57142857142856,0,0,1,118.89899109168363,48.92486241449938A128.57142857142856,128.57142857142856,0,0,0,128.5710785826187,0.29999500002499996Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M108.55067415553356,45.287873688118395A117.6190476190476,117.6190476190476,0,0,1,83.38108414279304,82.95682714511358A117.6190476190476,117.6190476190476,0,0,0,108.55067415553356,45.287873688118395Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M67.46775955069783,67.89201654837731A95.71428571428571,95.71428571428571,0,0,1,36.905250711119756,88.31323207620312L32.71393272839903,78.19449535810047A84.76190476190476,84.76190476190476,0,0,0,59.723213753733475,60.14747075141293Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M36.350932230362126,88.54283830880698A95.71428571428571,95.71428571428571,0,0,1,0.2999950000249978,95.7138155795488L0.2999950000250111,84.76137387906223A84.76190476190476,84.76190476190476,0,0,0,32.15961424764138,78.42410159070434Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-0.2999950000249678,205.23787598774598A205.2380952380952,205.2380952380952,0,0,1,-78.26397559393246,189.72987603747015L-69.88137609823445,169.49249064701422A183.33333333333331,183.33333333333331,0,0,0,-0.2999950000249954,183.33308788680526Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-57.86178986300871,138.90679311709624A150.47619047619048,150.47619047619048,0,0,1,-106.19039474010117,106.61465173778065L-98.44587529972756,98.87013229740705A139.52380952380952,139.52380952380952,0,0,0,-53.67048614437211,128.78809083553898Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-145.3372223660986,144.91296536841915A205.2380952380952,205.2380952380952,0,0,1,-189.50026980486626,78.81829407469019L-169.26288441441034,70.43569457899218A183.33333333333331,183.33333333333331,0,0,0,-129.848198159469,129.4239411617896Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-179.61118402441082,74.07267612864706A194.2857142857143,194.2857142857143,0,0,1,-194.2854826756497,0.29999500002505136L-183.33308788680526,0.29999500002498836A183.33333333333331,183.33333333333331,0,0,0,-169.49249064701417,69.88137609823461Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-238.095049101463,-0.2999950000249904A238.0952380952381,238.0952380952381,0,0,1,-220.0859457734134,-90.83787137890369L-209.96725676000634,-86.64657315611487A227.1428571428571,227.1428571428571,0,0,0,-227.14265903616686,-0.2999950000249489Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-78.19449535810047,-32.713932728399044A84.76190476190476,84.76190476190476,0,0,1,-60.147470751412904,-59.7232137537335A84.76190476190476,84.76190476190476,0,0,0,-78.19449535810047,-32.713932728399044Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-90.70135303139844,-91.1256100290779A128.57142857142856,128.57142857142856,0,0,1,-49.47918089525712,-118.66938485907971A128.57142857142856,128.57142857142856,0,0,0,-90.70135303139844,-91.1256100290779Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-44.73355520736079,-108.78028038813741A117.6190476190476,117.6190476190476,0,0,1,-0.29999500002505713,-117.61866504008515A117.6190476190476,117.6190476190476,0,0,0,-44.73355520736079,-108.78028038813741Z"
              transform="rotate(-11.25)"
            />
          </g>
          <g fill="#08519c">
            <path
              d="M0.29999500002501783,-183.33308788680526A183.33333333333331,183.33333333333331,0,0,1,69.88137609823448,-169.49249064701422A183.33333333333331,183.33333333333331,0,0,0,0.29999500002501783,-183.33308788680526Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M24.331272458636878,-57.956963246074615A62.857142857142854,62.857142857142854,0,0,1,44.234077251275906,-44.65833424895537A62.857142857142854,62.857142857142854,0,0,0,24.331272458636878,-57.956963246074615Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M75.63655352408517,-75.21229652640568A106.66666666666666,106.66666666666666,0,0,1,98.4319572687787,-41.09656391981087A106.66666666666666,106.66666666666666,0,0,0,75.63655352408517,-75.21229652640568Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M58.18656947867848,-23.77695397787924A62.857142857142854,62.857142857142854,0,0,1,62.85642696783858,-0.29999500002499996A62.857142857142854,62.857142857142854,0,0,0,58.18656947867848,-23.77695397787924Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M128.5710785826187,0.29999500002499996A128.57142857142856,128.57142857142856,0,0,1,118.89899109168363,48.92486241449938A128.57142857142856,128.57142857142856,0,0,0,128.5710785826187,0.29999500002499996Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M108.55067415553356,45.287873688118395A117.6190476190476,117.6190476190476,0,0,1,83.38108414279304,82.95682714511358A117.6190476190476,117.6190476190476,0,0,0,108.55067415553356,45.287873688118395Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M67.46775955069783,67.89201654837731A95.71428571428571,95.71428571428571,0,0,1,36.905250711119756,88.31323207620312A95.71428571428571,95.71428571428571,0,0,0,67.46775955069783,67.89201654837731Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M48.924862414499394,118.89899109168361A128.57142857142856,128.57142857142856,0,0,1,0.2999950000250033,128.5710785826187L0.2999950000249978,95.7138155795488A95.71428571428571,95.71428571428571,0,0,0,36.350932230362126,88.54283830880698Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-0.2999950000249628,227.14265903616686A227.1428571428571,227.1428571428571,0,0,1,-86.6465731561148,209.96725676000636L-78.26397559393246,189.72987603747015A205.2380952380952,205.2380952380952,0,0,0,-0.2999950000249678,205.23787598774598Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-57.86178986300871,138.90679311709624A150.47619047619048,150.47619047619048,0,0,1,-106.19039474010117,106.61465173778065A150.47619047619048,150.47619047619048,0,0,0,-57.86178986300871,138.90679311709624Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-160.8262430000571,160.4019860023777A227.1428571428571,227.1428571428571,0,0,1,-209.73765052740248,87.20089163687251L-189.50026980486626,78.81829407469019A205.2380952380952,205.2380952380952,0,0,0,-145.3372223660986,144.91296536841915Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-179.61118402441082,74.07267612864706A194.2857142857143,194.2857142857143,0,0,1,-194.2854826756497,0.29999500002505136A194.2857142857143,194.2857142857143,0,0,0,-179.61118402441082,74.07267612864706Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-249.04743836523983,-0.29999500002495477A249.047619047619,249.047619047619,0,0,1,-230.20463404631315,-95.02916929496438L-220.0859457734134,-90.83787137890369A238.0952380952381,238.0952380952381,0,0,0,-238.095049101463,-0.2999950000249904Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-78.19449535810047,-32.713932728399044A84.76190476190476,84.76190476190476,0,0,1,-60.147470751412904,-59.7232137537335A84.76190476190476,84.76190476190476,0,0,0,-78.19449535810047,-32.713932728399044Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-90.70135303139844,-91.1256100290779A128.57142857142856,128.57142857142856,0,0,1,-49.47918089525712,-118.66938485907971A128.57142857142856,128.57142857142856,0,0,0,-90.70135303139844,-91.1256100290779Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-44.73355520736079,-108.78028038813741A117.6190476190476,117.6190476190476,0,0,1,-0.29999500002505713,-117.61866504008515A117.6190476190476,117.6190476190476,0,0,0,-44.73355520736079,-108.78028038813741Z"
              transform="rotate(-11.25)"
            />
          </g>
          <g fill="#08306b">
            <path
              d="M0.29999500002501783,-183.33308788680526A183.33333333333331,183.33333333333331,0,0,1,69.88137609823448,-169.49249064701422A183.33333333333331,183.33333333333331,0,0,0,0.29999500002501783,-183.33308788680526Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M24.331272458636878,-57.956963246074615A62.857142857142854,62.857142857142854,0,0,1,44.234077251275906,-44.65833424895537A62.857142857142854,62.857142857142854,0,0,0,24.331272458636878,-57.956963246074615Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M75.63655352408517,-75.21229652640568A106.66666666666666,106.66666666666666,0,0,1,98.4319572687787,-41.09656391981087A106.66666666666666,106.66666666666666,0,0,0,75.63655352408517,-75.21229652640568Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M58.18656947867848,-23.77695397787924A62.857142857142854,62.857142857142854,0,0,1,62.85642696783858,-0.29999500002499996A62.857142857142854,62.857142857142854,0,0,0,58.18656947867848,-23.77695397787924Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M128.5710785826187,0.29999500002499996A128.57142857142856,128.57142857142856,0,0,1,118.89899109168363,48.92486241449938A128.57142857142856,128.57142857142856,0,0,0,128.5710785826187,0.29999500002499996Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M108.55067415553356,45.287873688118395A117.6190476190476,117.6190476190476,0,0,1,83.38108414279304,82.95682714511358A117.6190476190476,117.6190476190476,0,0,0,108.55067415553356,45.287873688118395Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M67.46775955069783,67.89201654837731A95.71428571428571,95.71428571428571,0,0,1,36.905250711119756,88.31323207620312A95.71428571428571,95.71428571428571,0,0,0,67.46775955069783,67.89201654837731Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M48.924862414499394,118.89899109168361A128.57142857142856,128.57142857142856,0,0,1,0.2999950000250033,128.5710785826187A128.57142857142856,128.57142857142856,0,0,0,48.924862414499394,118.89899109168361Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-0.299995000025005,238.095049101463A238.0952380952381,238.0952380952381,0,0,1,-90.83787137890359,220.08594577341344L-86.6465731561148,209.96725676000636A227.1428571428571,227.1428571428571,0,0,0,-0.2999950000249628,227.14265903616686Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-57.86178986300871,138.90679311709624A150.47619047619048,150.47619047619048,0,0,1,-106.19039474010117,106.61465173778065A150.47619047619048,150.47619047619048,0,0,0,-57.86178986300871,138.90679311709624Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-160.8262430000571,160.4019860023777A227.1428571428571,227.1428571428571,0,0,1,-209.73765052740248,87.20089163687251A227.1428571428571,227.1428571428571,0,0,0,-160.8262430000571,160.4019860023777Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-209.9672567600063,86.64657315611491A227.1428571428571,227.1428571428571,0,0,1,-227.14265903616686,0.29999500002500457L-194.2854826756497,0.29999500002505136A194.2857142857143,194.2857142857143,0,0,0,-179.61118402441082,74.07267612864706Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-259.9998269287885,-0.29999500002495366A260,260,0,0,1,-240.32332167228645,-99.22046694305934L-230.20463404631315,-95.02916929496438A249.047619047619,249.047619047619,0,0,0,-249.04743836523983,-0.29999500002495477Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-78.19449535810047,-32.713932728399044A84.76190476190476,84.76190476190476,0,0,1,-60.147470751412904,-59.7232137537335A84.76190476190476,84.76190476190476,0,0,0,-78.19449535810047,-32.713932728399044Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-90.70135303139844,-91.1256100290779A128.57142857142856,128.57142857142856,0,0,1,-49.47918089525712,-118.66938485907971A128.57142857142856,128.57142857142856,0,0,0,-90.70135303139844,-91.1256100290779Z"
              transform="rotate(-11.25)"
            />
            <path
              d="M-44.73355520736079,-108.78028038813741A117.6190476190476,117.6190476190476,0,0,1,-0.29999500002505713,-117.61866504008515A117.6190476190476,117.6190476190476,0,0,0,-44.73355520736079,-108.78028038813741Z"
              transform="rotate(-11.25)"
            />
          </g>
        </g>
        <g class="direction-labels" font-weight="500" font-size="28">
          <g text-anchor="middle" transform="rotate(-90) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{N}</text>
          </g>
          <g text-anchor="middle" transform="rotate(-67.5) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{NNE}</text>
          </g>
          <g text-anchor="middle" transform="rotate(-45) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{NE}</text>
          </g>
          <g text-anchor="middle" transform="rotate(-22.5) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{ENE}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(-1.4210854715202004e-14) translate(280,0)"
          >
            <text transform="rotate(-90)translate(0,6)">{E}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(22.499999999999986) translate(280,0)"
          >
            <text transform="rotate(-90)translate(0,6)">{ESE}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(45.00000000000003) translate(280,0)"
          >
            <text transform="rotate(-90)translate(0,6)">{SE}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(67.49999999999997) translate(280,0)"
          >
            <text transform="rotate(-90)translate(0,6)">{SSE}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(90.00000000000003) translate(280,0)"
          >
            <text transform="rotate(-90)translate(0,6)">{S}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(112.49999999999997) translate(280,0)"
          >
            <text transform="rotate(-90)translate(0,6)">{SSW}</text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(135.00000000000003) translate(280,0)"
          >
            <text transform="rotate(-90)translate(0,6)">{SW}</text>
          </g>
          <g text-anchor="middle" transform="rotate(157.5) translate(280,0)">
            <text transform="rotate(-90)translate(0,6)">{WSW}</text>
          </g>
          <g text-anchor="middle" transform="rotate(180) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{W}</text>
          </g>
          <g text-anchor="middle" transform="rotate(202.5) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{WNW}</text>
          </g>
          <g text-anchor="middle" transform="rotate(225) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{NW}</text>
          </g>
          <g text-anchor="middle" transform="rotate(247.5) translate(280,0)">
            <text transform="rotate(90)translate(0,6)">{NNW}</text>
          </g>
        </g>
        <g class="yAxis">
          <circle fill="none" stroke="gray" stroke-dasharray="4,4" r="30" />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="84.76190476190476"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="139.52380952380952"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="194.2857142857143"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="249.047619047619"
          />
        </g>
      </g>
    </svg>
  );
};

// This one can NEVER have cardinal direction labels
export const WindRose10 = () => (
  <svg
    viewBox="0,0,600,600"
    font-family="sans-serif"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(300,300)">
      <g class="axes">
        <g class="axis" transform="rotate(-90)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(-80)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(-70)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(-60)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(-50)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(-40)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(-30)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(-20)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(-10)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(0)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(10)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(20)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(30)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(40)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(50)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(60)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(70)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(80)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(90)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(100)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(110)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(120)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(130)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(140)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(150)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(160)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(170)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(180)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(190)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(200)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(210)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(220)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(230)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(240)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(250)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
        <g class="axis" transform="rotate(260)">
          <line
            x1="30"
            x2="242.3076923076923"
            fill="none"
            stroke="gray"
            stroke-dasharray="1,4"
          />
        </g>
      </g>
      <g class="rings">
        <g fill="#f7fbff">
          <path
            d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,4.91374745802249,-29.59484897611031A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
            transform="rotate(-5)"
          />
          <path
            d="M5.504622261801526,-29.490661805983247A30,30,0,0,1,9.978188186034153,-28.291973429297727A30,30,0,0,0,5.504622261801526,-29.490661805983247Z"
            transform="rotate(-5)"
          />
          <path
            d="M10.541994361626475,-28.086764763486656A30,30,0,0,1,14.739446715220014,-26.129460586265267A30,30,0,0,0,10.541994361626475,-28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M15.25905329727994,-25.829465586240264A30,30,0,0,1,19.05285461448384,-23.173017305464167A30,30,0,0,0,15.25905329727994,-25.829465586240264Z"
            transform="rotate(-5)"
          />
          <path
            d="M19.5124736199491,-22.787351167496205A30,30,0,0,1,22.787351167496205,-19.512473619949095A30,30,0,0,0,19.5124736199491,-22.787351167496205Z"
            transform="rotate(-5)"
          />
          <path
            d="M23.17301730546417,-19.052854614483838A30,30,0,0,1,25.829465586240264,-15.259053297279944A30,30,0,0,0,23.17301730546417,-19.052854614483838Z"
            transform="rotate(-5)"
          />
          <path
            d="M26.129460586265264,-14.739446715220017A30,30,0,0,1,28.086764763486656,-10.541994361626474A30,30,0,0,0,26.129460586265264,-14.739446715220017Z"
            transform="rotate(-5)"
          />
          <path
            d="M28.291973429297727,-9.978188186034151A30,30,0,0,1,29.490661805983247,-5.504622261801524A30,30,0,0,0,28.291973429297727,-9.978188186034151Z"
            transform="rotate(-5)"
          />
          <path
            d="M29.59484897611031,-4.913747458022488A30,30,0,0,1,29.998500012499957,-0.29999500002499996A30,30,0,0,0,29.59484897611031,-4.913747458022488Z"
            transform="rotate(-5)"
          />
          <path
            d="M29.998500012499957,0.29999500002499996A30,30,0,0,1,29.59484897611031,4.913747458022488A30,30,0,0,0,29.998500012499957,0.29999500002499996Z"
            transform="rotate(-5)"
          />
          <path
            d="M29.490661805983247,5.504622261801524A30,30,0,0,1,28.291973429297727,9.978188186034151A30,30,0,0,0,29.490661805983247,5.504622261801524Z"
            transform="rotate(-5)"
          />
          <path
            d="M28.086764763486656,10.541994361626474A30,30,0,0,1,26.129460586265267,14.739446715220012A30,30,0,0,0,28.086764763486656,10.541994361626474Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.1519354089118,24.10548537404926A47.69230769230769,47.69230769230769,0,0,1,36.72653757314142,30.425608472245592L23.173017305464178,19.05285461448383A30,30,0,0,0,25.829465586240268,15.259053297279937Z"
            transform="rotate(-5)"
          />
          <path
            d="M22.7873511674962,19.5124736199491A30,30,0,0,1,19.512473619949102,22.7873511674962A30,30,0,0,0,22.7873511674962,19.5124736199491Z"
            transform="rotate(-5)"
          />
          <path
            d="M19.05285461448384,23.173017305464167A30,30,0,0,1,15.259053297279952,25.829465586240257A30,30,0,0,0,19.05285461448384,23.173017305464167Z"
            transform="rotate(-5)"
          />
          <path
            d="M14.739446715220014,26.129460586265267A30,30,0,0,1,10.541994361626475,28.086764763486656A30,30,0,0,0,14.739446715220014,26.129460586265267Z"
            transform="rotate(-5)"
          />
          <path
            d="M9.978188186034153,28.291973429297727A30,30,0,0,1,5.504622261801532,29.490661805983244A30,30,0,0,0,9.978188186034153,28.291973429297727Z"
            transform="rotate(-5)"
          />
          <path
            d="M4.913747458022496,29.59484897611031A30,30,0,0,1,0.2999950000250154,29.998500012499957A30,30,0,0,0,4.913747458022496,29.59484897611031Z"
            transform="rotate(-5)"
          />
          <path
            d="M-0.29999500002499835,29.998500012499957A30,30,0,0,1,-4.913747458022479,29.594848976110313A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
            transform="rotate(-5)"
          />
          <path
            d="M-5.504622261801515,29.490661805983247A30,30,0,0,1,-9.978188186034135,28.29197342929773A30,30,0,0,0,-5.504622261801515,29.490661805983247Z"
            transform="rotate(-5)"
          />
          <path
            d="M-10.541994361626472,28.086764763486656A30,30,0,0,1,-14.739446715220016,26.129460586265264A30,30,0,0,0,-10.541994361626472,28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M-15.25905329727993,25.82946558624027A30,30,0,0,1,-19.052854614483834,23.173017305464175A30,30,0,0,0,-15.25905329727993,25.82946558624027Z"
            transform="rotate(-5)"
          />
          <path
            d="M-19.51247361994909,22.787351167496208A30,30,0,0,1,-22.787351167496205,19.512473619949095A30,30,0,0,0,-19.51247361994909,22.787351167496208Z"
            transform="rotate(-5)"
          />
          <path
            d="M-36.726537573141414,30.425608472245607A47.69230769230769,47.69230769230769,0,0,1,-41.15193540891178,24.105485374049287L-25.82946558624026,15.259053297279948A30,30,0,0,0,-23.17301730546416,19.052854614483852Z"
            transform="rotate(-5)"
          />
          <path
            d="M-41.45193040893679,23.585878791989355A47.69230769230769,47.69230769230769,0,0,1,-44.712618649134434,16.59331029526138L-28.086764763486652,10.541994361626482A30,30,0,0,0,-26.129460586265253,14.739446715220032Z"
            transform="rotate(-5)"
          />
          <path
            d="M-28.291973429297716,9.978188186034172A30,30,0,0,1,-29.490661805983244,5.50462226180154A30,30,0,0,0,-28.291973429297716,9.978188186034172Z"
            transform="rotate(-5)"
          />
          <path
            d="M-29.59484897611031,4.913747458022492A30,30,0,0,1,-29.998500012499957,0.29999500002499724A30,30,0,0,0,-29.59484897611031,4.913747458022492Z"
            transform="rotate(-5)"
          />
          <path
            d="M-47.69136416603861,-0.2999950000249982A47.69230769230769,47.69230769230769,0,0,1,-47.01891876750695,-7.986081075993011L-29.59484897611031,-4.913747458022484A30,30,0,0,0,-29.998500012499957,-0.2999950000249899Z"
            transform="rotate(-5)"
          />
          <path
            d="M-29.49066180598325,-5.504622261801507A30,30,0,0,1,-28.29197342929773,-9.97818818603414A30,30,0,0,0,-29.49066180598325,-5.504622261801507Z"
            transform="rotate(-5)"
          />
          <path
            d="M-28.086764763486666,-10.541994361626452A30,30,0,0,1,-26.12946058626527,-14.739446715220001A30,30,0,0,0,-28.086764763486666,-10.541994361626452Z"
            transform="rotate(-5)"
          />
          <path
            d="M-25.829465586240264,-15.25905329727994A30,30,0,0,1,-23.173017305464167,-19.052854614483845A30,30,0,0,0,-25.829465586240264,-15.25905329727994Z"
            transform="rotate(-5)"
          />
          <path
            d="M-22.787351167496208,-19.51247361994909A30,30,0,0,1,-19.5124736199491,-22.787351167496205A30,30,0,0,0,-22.787351167496208,-19.51247361994909Z"
            transform="rotate(-5)"
          />
          <path
            d="M-19.052854614483852,-23.17301730546416A30,30,0,0,1,-15.259053297279948,-25.82946558624026A30,30,0,0,0,-19.052854614483852,-23.17301730546416Z"
            transform="rotate(-5)"
          />
          <path
            d="M-14.739446715220033,-26.129460586265253A30,30,0,0,1,-10.541994361626484,-28.086764763486652A30,30,0,0,0,-14.739446715220033,-26.129460586265253Z"
            transform="rotate(-5)"
          />
          <path
            d="M-9.978188186034174,-28.291973429297716A30,30,0,0,1,-5.504622261801542,-29.490661805983244A30,30,0,0,0,-9.978188186034174,-28.291973429297716Z"
            transform="rotate(-5)"
          />
          <path
            d="M-4.913747458022494,-29.59484897611031A30,30,0,0,1,-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-4.913747458022494,-29.59484897611031Z"
            transform="rotate(-5)"
          />
        </g>
        <g fill="#deebf7">
          <path
            d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,4.91374745802249,-29.59484897611031A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
            transform="rotate(-5)"
          />
          <path
            d="M5.504622261801526,-29.490661805983247A30,30,0,0,1,9.978188186034153,-28.291973429297727A30,30,0,0,0,5.504622261801526,-29.490661805983247Z"
            transform="rotate(-5)"
          />
          <path
            d="M10.541994361626475,-28.086764763486656A30,30,0,0,1,14.739446715220014,-26.129460586265267A30,30,0,0,0,10.541994361626475,-28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M15.25905329727994,-25.829465586240264A30,30,0,0,1,19.05285461448384,-23.173017305464167A30,30,0,0,0,15.25905329727994,-25.829465586240264Z"
            transform="rotate(-5)"
          />
          <path
            d="M19.5124736199491,-22.787351167496205A30,30,0,0,1,22.787351167496205,-19.512473619949095A30,30,0,0,0,19.5124736199491,-22.787351167496205Z"
            transform="rotate(-5)"
          />
          <path
            d="M23.17301730546417,-19.052854614483838A30,30,0,0,1,25.829465586240264,-15.259053297279944A30,30,0,0,0,23.17301730546417,-19.052854614483838Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.45193040893679,-23.585878791989344A47.69230769230769,47.69230769230769,0,0,1,44.71261864913445,-16.59331029526135L28.086764763486656,-10.541994361626474A30,30,0,0,0,26.129460586265264,-14.739446715220017Z"
            transform="rotate(-5)"
          />
          <path
            d="M28.291973429297727,-9.978188186034151A30,30,0,0,1,29.490661805983247,-5.504622261801524A30,30,0,0,0,28.291973429297727,-9.978188186034151Z"
            transform="rotate(-5)"
          />
          <path
            d="M29.59484897611031,-4.913747458022488A30,30,0,0,1,29.998500012499957,-0.29999500002499996A30,30,0,0,0,29.59484897611031,-4.913747458022488Z"
            transform="rotate(-5)"
          />
          <path
            d="M65.38392716864018,0.29999500002499996A65.38461538461539,65.38461538461539,0,0,1,64.44269198312593,11.058362399652147L29.59484897611031,4.913747458022488A30,30,0,0,0,29.998500012499957,0.29999500002499996Z"
            transform="rotate(-5)"
          />
          <path
            d="M46.91473159737989,8.576955879772067A47.69230769230769,47.69230769230769,0,0,1,44.91782731494551,16.02950411966903L28.291973429297727,9.978188186034151A30,30,0,0,0,29.490661805983247,5.504622261801524Z"
            transform="rotate(-5)"
          />
          <path
            d="M28.086764763486656,10.541994361626474A30,30,0,0,1,26.129460586265267,14.739446715220012A30,30,0,0,0,28.086764763486656,10.541994361626474Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.1519354089118,24.10548537404926A47.69230769230769,47.69230769230769,0,0,1,36.72653757314142,30.425608472245592A47.69230769230769,47.69230769230769,0,0,0,41.1519354089118,24.10548537404926Z"
            transform="rotate(-5)"
          />
          <path
            d="M22.7873511674962,19.5124736199491A30,30,0,0,1,19.512473619949102,22.7873511674962A30,30,0,0,0,22.7873511674962,19.5124736199491Z"
            transform="rotate(-5)"
          />
          <path
            d="M30.425608472245614,36.72653757314141A47.69230769230769,47.69230769230769,0,0,1,24.105485374049287,41.15193540891178L15.259053297279952,25.829465586240257A30,30,0,0,0,19.05285461448384,23.173017305464167Z"
            transform="rotate(-5)"
          />
          <path
            d="M14.739446715220014,26.129460586265267A30,30,0,0,1,10.541994361626475,28.086764763486656A30,30,0,0,0,14.739446715220014,26.129460586265267Z"
            transform="rotate(-5)"
          />
          <path
            d="M9.978188186034153,28.291973429297727A30,30,0,0,1,5.504622261801532,29.490661805983244A30,30,0,0,0,9.978188186034153,28.291973429297727Z"
            transform="rotate(-5)"
          />
          <path
            d="M7.986081075993041,47.01891876750695A47.69230769230769,47.69230769230769,0,0,1,0.2999950000250281,47.69136416603861L0.2999950000250154,29.998500012499957A30,30,0,0,0,4.913747458022496,29.59484897611031Z"
            transform="rotate(-5)"
          />
          <path
            d="M-0.29999500002499835,29.998500012499957A30,30,0,0,1,-4.913747458022479,29.594848976110313A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
            transform="rotate(-5)"
          />
          <path
            d="M-5.504622261801515,29.490661805983247A30,30,0,0,1,-9.978188186034135,28.29197342929773A30,30,0,0,0,-5.504622261801515,29.490661805983247Z"
            transform="rotate(-5)"
          />
          <path
            d="M-10.541994361626472,28.086764763486656A30,30,0,0,1,-14.739446715220016,26.129460586265264A30,30,0,0,0,-10.541994361626472,28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M-15.25905329727993,25.82946558624027A30,30,0,0,1,-19.052854614483834,23.173017305464175A30,30,0,0,0,-15.25905329727993,25.82946558624027Z"
            transform="rotate(-5)"
          />
          <path
            d="M-19.51247361994909,22.787351167496208A30,30,0,0,1,-22.787351167496205,19.512473619949095A30,30,0,0,0,-19.51247361994909,22.787351167496208Z"
            transform="rotate(-5)"
          />
          <path
            d="M-36.726537573141414,30.425608472245607A47.69230769230769,47.69230769230769,0,0,1,-41.15193540891178,24.105485374049287A47.69230769230769,47.69230769230769,0,0,0,-36.726537573141414,30.425608472245607Z"
            transform="rotate(-5)"
          />
          <path
            d="M-72.09625426988701,41.278387422243036A83.07692307692307,83.07692307692307,0,0,1,-77.9636582552152,28.6956989702814L-44.712618649134434,16.59331029526138A47.69230769230769,47.69230769230769,0,0,0,-41.45193040893679,23.585878791989355Z"
            transform="rotate(-5)"
          />
          <path
            d="M-28.291973429297716,9.978188186034172A30,30,0,0,1,-29.490661805983244,5.50462226180154A30,30,0,0,0,-28.291973429297716,9.978188186034172Z"
            transform="rotate(-5)"
          />
          <path
            d="M-47.01891876750695,7.986081075993022A47.69230769230769,47.69230769230769,0,0,1,-47.69136416603861,0.2999950000250099L-29.998500012499957,0.29999500002499724A30,30,0,0,0,-29.59484897611031,4.913747458022492Z"
            transform="rotate(-5)"
          />
          <path
            d="M-47.69136416603861,-0.2999950000249982A47.69230769230769,47.69230769230769,0,0,1,-47.01891876750695,-7.986081075993011A47.69230769230769,47.69230769230769,0,0,0,-47.69136416603861,-0.2999950000249982Z"
            transform="rotate(-5)"
          />
          <path
            d="M-46.91473159737989,-8.576955879772054A47.69230769230769,47.69230769230769,0,0,1,-44.91782731494553,-16.029504119669L-28.29197342929773,-9.97818818603414A30,30,0,0,0,-29.49066180598325,-5.504622261801507Z"
            transform="rotate(-5)"
          />
          <path
            d="M-28.086764763486666,-10.541994361626452A30,30,0,0,1,-26.12946058626527,-14.739446715220001A30,30,0,0,0,-28.086764763486666,-10.541994361626452Z"
            transform="rotate(-5)"
          />
          <path
            d="M-25.829465586240264,-15.25905329727994A30,30,0,0,1,-23.173017305464167,-19.052854614483845A30,30,0,0,0,-25.829465586240264,-15.25905329727994Z"
            transform="rotate(-5)"
          />
          <path
            d="M-22.787351167496208,-19.51247361994909A30,30,0,0,1,-19.5124736199491,-22.787351167496205A30,30,0,0,0,-22.787351167496208,-19.51247361994909Z"
            transform="rotate(-5)"
          />
          <path
            d="M-19.052854614483852,-23.17301730546416A30,30,0,0,1,-15.259053297279948,-25.82946558624026A30,30,0,0,0,-19.052854614483852,-23.17301730546416Z"
            transform="rotate(-5)"
          />
          <path
            d="M-14.739446715220033,-26.129460586265253A30,30,0,0,1,-10.541994361626484,-28.086764763486652A30,30,0,0,0,-14.739446715220033,-26.129460586265253Z"
            transform="rotate(-5)"
          />
          <path
            d="M-9.978188186034174,-28.291973429297716A30,30,0,0,1,-5.504622261801542,-29.490661805983244A30,30,0,0,0,-9.978188186034174,-28.291973429297716Z"
            transform="rotate(-5)"
          />
          <path
            d="M-4.913747458022494,-29.59484897611031A30,30,0,0,1,-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-4.913747458022494,-29.59484897611031Z"
            transform="rotate(-5)"
          />
        </g>
        <g fill="#c6dbef">
          <path
            d="M0.29999500002500695,-47.69136416603861A47.69230769230769,47.69230769230769,0,0,1,7.98608107599303,-47.01891876750695L4.91374745802249,-29.59484897611031A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
            transform="rotate(-5)"
          />
          <path
            d="M11.64923720343119,-64.33850481299886A65.38461538461539,65.38461538461539,0,0,1,22.08071705361724,-61.54339821127998L9.978188186034153,-28.291973429297727A30,30,0,0,0,5.504622261801526,-29.490661805983247Z"
            transform="rotate(-5)"
          />
          <path
            d="M10.541994361626475,-28.086764763486656A30,30,0,0,1,14.739446715220014,-26.129460586265267A30,30,0,0,0,10.541994361626475,-28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M15.25905329727994,-25.829465586240264A30,30,0,0,1,19.05285461448384,-23.173017305464167A30,30,0,0,0,15.25905329727994,-25.829465586240264Z"
            transform="rotate(-5)"
          />
          <path
            d="M30.885227477710863,-36.34087143517345A47.69230769230769,47.69230769230769,0,0,1,36.34087143517345,-30.88522747771086L22.787351167496205,-19.512473619949095A30,30,0,0,0,19.5124736199491,-22.787351167496205Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.833033415222204,-53.17065913584407A83.07692307692307,83.07692307692307,0,0,1,71.796259269862,-41.79799400430295L25.829465586240264,-15.259053297279944A30,30,0,0,0,23.17301730546417,-19.052854614483838Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.45193040893679,-23.585878791989344A47.69230769230769,47.69230769230769,0,0,1,44.71261864913445,-16.59331029526135A47.69230769230769,47.69230769230769,0,0,0,41.45193040893679,-23.585878791989344Z"
            transform="rotate(-5)"
          />
          <path
            d="M61.54339821127998,-22.08071705361724A65.38461538461539,65.38461538461539,0,0,1,64.33850481299886,-11.649237203431182L29.490661805983247,-5.504622261801524A30,30,0,0,0,28.291973429297727,-9.978188186034151Z"
            transform="rotate(-5)"
          />
          <path
            d="M47.01891876750695,-7.986081075993032A47.69230769230769,47.69230769230769,0,0,1,47.69136416603861,-0.29999500002499996L29.998500012499957,-0.29999500002499996A30,30,0,0,0,29.59484897611031,-4.913747458022488Z"
            transform="rotate(-5)"
          />
          <path
            d="M83.07638142654596,0.29999500002499996A83.07692307692307,83.07692307692307,0,0,1,81.8663581061254,14.130624839993011L64.44269198312593,11.058362399652147A65.38461538461539,65.38461538461539,0,0,0,65.38392716864018,0.29999500002499996Z"
            transform="rotate(-5)"
          />
          <path
            d="M46.91473159737989,8.576955879772067A47.69230769230769,47.69230769230769,0,0,1,44.91782731494551,16.02950411966903A47.69230769230769,47.69230769230769,0,0,0,46.91473159737989,8.576955879772067Z"
            transform="rotate(-5)"
          />
          <path
            d="M28.086764763486656,10.541994361626474A30,30,0,0,1,26.129460586265267,14.739446715220012A30,30,0,0,0,28.086764763486656,10.541994361626474Z"
            transform="rotate(-5)"
          />
          <path
            d="M56.47414442722144,32.951766875350046A65.38461538461539,65.38461538461539,0,0,1,50.27982714581678,41.79816875391635L36.72653757314142,30.425608472245592A47.69230769230769,47.69230769230769,0,0,0,41.1519354089118,24.10548537404926Z"
            transform="rotate(-5)"
          />
          <path
            d="M36.34087143517345,30.885227477710863A47.69230769230769,47.69230769230769,0,0,1,30.88522747771087,36.34087143517344L19.512473619949102,22.7873511674962A30,30,0,0,0,22.7873511674962,19.5124736199491Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.79816875391637,50.27982714581676A65.38461538461539,65.38461538461539,0,0,1,32.951766875350074,56.474144427221425L24.105485374049287,41.15193540891178A47.69230769230769,47.69230769230769,0,0,0,30.425608472245614,36.72653757314141Z"
            transform="rotate(-5)"
          />
          <path
            d="M14.739446715220014,26.129460586265267A30,30,0,0,1,10.541994361626475,28.086764763486656A30,30,0,0,0,14.739446715220014,26.129460586265267Z"
            transform="rotate(-5)"
          />
          <path
            d="M9.978188186034153,28.291973429297727A30,30,0,0,1,5.504622261801532,29.490661805983244A30,30,0,0,0,9.978188186034153,28.291973429297727Z"
            transform="rotate(-5)"
          />
          <path
            d="M17.202878343320215,99.28997354480164A100.76923076923077,100.76923076923077,0,0,1,0.2999950000250522,100.76878421824115L0.2999950000250281,47.69136416603861A47.69230769230769,47.69230769230769,0,0,0,7.986081075993041,47.01891876750695Z"
            transform="rotate(-5)"
          />
          <path
            d="M-0.29999500002499474,83.07638142654596A83.07692307692307,83.07692307692307,0,0,1,-14.130624839992988,81.86635810612542L-4.913747458022479,29.594848976110313A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
            transform="rotate(-5)"
          />
          <path
            d="M-8.576955879772058,46.91473159737989A47.69230769230769,47.69230769230769,0,0,1,-16.029504119669,44.91782731494552L-9.978188186034135,28.29197342929773A30,30,0,0,0,-5.504622261801515,29.490661805983247Z"
            transform="rotate(-5)"
          />
          <path
            d="M-10.541994361626472,28.086764763486656A30,30,0,0,1,-14.739446715220016,26.129460586265264A30,30,0,0,0,-10.541994361626472,28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M-77.18273396884275,133.08442670105802A153.84615384615384,153.84615384615384,0,0,1,-98.66040397821669,118.0456002573138L-19.052854614483834,23.173017305464175A30,30,0,0,0,-15.25905329727993,25.82946558624027Z"
            transform="rotate(-5)"
          />
          <path
            d="M-30.88522747771087,36.34087143517344A47.69230769230769,47.69230769230769,0,0,1,-36.34087143517344,30.885227477710874L-22.787351167496205,19.512473619949095A30,30,0,0,0,-19.51247361994909,22.787351167496208Z"
            transform="rotate(-5)"
          />
          <path
            d="M-50.27982714581676,41.79816875391638A65.38461538461539,65.38461538461539,0,0,1,-56.474144427221425,32.951766875350074L-41.15193540891178,24.105485374049287A47.69230769230769,47.69230769230769,0,0,0,-36.726537573141414,30.425608472245607Z"
            transform="rotate(-5)"
          />
          <path
            d="M-72.09625426988701,41.278387422243036A83.07692307692307,83.07692307692307,0,0,1,-77.9636582552152,28.6956989702814A83.07692307692307,83.07692307692307,0,0,0,-72.09625426988701,41.278387422243036Z"
            transform="rotate(-5)"
          />
          <path
            d="M-28.291973429297716,9.978188186034172A30,30,0,0,1,-29.490661805983244,5.50462226180154A30,30,0,0,0,-28.291973429297716,9.978188186034172Z"
            transform="rotate(-5)"
          />
          <path
            d="M-134.13713140294885,23.347372474718526A136.15384615384616,136.15384615384616,0,0,1,-136.15351565598726,0.2999950000250227L-47.69136416603861,0.2999950000250099A47.69230769230769,47.69230769230769,0,0,0,-47.01891876750695,7.986081075993022Z"
            transform="rotate(-5)"
          />
          <path
            d="M-136.15351565598726,-0.29999500002498936A136.15384615384616,136.15384615384616,0,0,1,-134.13713140294885,-23.34737247471849L-47.01891876750695,-7.986081075993011A47.69230769230769,47.69230769230769,0,0,0,-47.69136416603861,-0.2999950000249982Z"
            transform="rotate(-5)"
          />
          <path
            d="M-46.91473159737989,-8.576955879772054A47.69230769230769,47.69230769230769,0,0,1,-44.91782731494553,-16.029504119669A47.69230769230769,47.69230769230769,0,0,0,-46.91473159737989,-8.576955879772054Z"
            transform="rotate(-5)"
          />
          <path
            d="M-28.086764763486666,-10.541994361626452A30,30,0,0,1,-26.12946058626527,-14.739446715220001A30,30,0,0,0,-28.086764763486666,-10.541994361626452Z"
            transform="rotate(-5)"
          />
          <path
            d="M-25.829465586240264,-15.25905329727994A30,30,0,0,1,-23.173017305464167,-19.052854614483845A30,30,0,0,0,-25.829465586240264,-15.25905329727994Z"
            transform="rotate(-5)"
          />
          <path
            d="M-22.787351167496208,-19.51247361994909A30,30,0,0,1,-19.5124736199491,-22.787351167496205A30,30,0,0,0,-22.787351167496208,-19.51247361994909Z"
            transform="rotate(-5)"
          />
          <path
            d="M-30.425608472245614,-36.72653757314141A47.69230769230769,47.69230769230769,0,0,1,-24.10548537404929,-41.15193540891178L-15.259053297279948,-25.82946558624026A30,30,0,0,0,-19.052854614483852,-23.17301730546416Z"
            transform="rotate(-5)"
          />
          <path
            d="M-14.739446715220033,-26.129460586265253A30,30,0,0,1,-10.541994361626484,-28.086764763486652A30,30,0,0,0,-14.739446715220033,-26.129460586265253Z"
            transform="rotate(-5)"
          />
          <path
            d="M-22.080717053617303,-61.54339821127996A65.38461538461539,65.38461538461539,0,0,1,-11.64923720343121,-64.33850481299886L-5.504622261801542,-29.490661805983244A30,30,0,0,0,-9.978188186034174,-28.291973429297716Z"
            transform="rotate(-5)"
          />
          <path
            d="M-4.913747458022494,-29.59484897611031A30,30,0,0,1,-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-4.913747458022494,-29.59484897611031Z"
            transform="rotate(-5)"
          />
        </g>
        <g fill="#9ecae1">
          <path
            d="M0.29999500002501434,-136.15351565598726A136.15384615384616,136.15384615384616,0,0,1,23.347372474718547,-134.13713140294885L7.98608107599303,-47.01891876750695A47.69230769230769,47.69230769230769,0,0,0,0.29999500002500695,-47.69136416603861Z"
            transform="rotate(-5)"
          />
          <path
            d="M27.01049086789314,-151.45650344679788A153.84615384615384,153.84615384615384,0,0,1,52.33648046311592,-144.6704249872389L22.08071705361724,-61.54339821127998A65.38461538461539,65.38461538461539,0,0,0,11.64923720343119,-64.33850481299886Z"
            transform="rotate(-5)"
          />
          <path
            d="M10.541994361626475,-28.086764763486656A30,30,0,0,1,14.739446715220014,-26.129460586265267A30,30,0,0,0,10.541994361626475,-28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M24.105485374049266,-41.1519354089118A47.69230769230769,47.69230769230769,0,0,1,30.425608472245607,-36.726537573141414L19.05285461448384,-23.173017305464167A30,30,0,0,0,15.25905329727994,-25.829465586240264Z"
            transform="rotate(-5)"
          />
          <path
            d="M53.630278141309326,-63.44736727725424A83.07692307692307,83.07692307692307,0,0,1,63.44736727725424,-53.630278141309326L36.34087143517345,-30.88522747771086A47.69230769230769,47.69230769230769,0,0,0,30.885227477710863,-36.34087143517345Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.833033415222204,-53.17065913584407A83.07692307692307,83.07692307692307,0,0,1,71.796259269862,-41.79799400430295A83.07692307692307,83.07692307692307,0,0,0,63.833033415222204,-53.17065913584407Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.45193040893679,-23.585878791989344A47.69230769230769,47.69230769230769,0,0,1,44.71261864913445,-16.59331029526135A47.69230769230769,47.69230769230769,0,0,0,41.45193040893679,-23.585878791989344Z"
            transform="rotate(-5)"
          />
          <path
            d="M61.54339821127998,-22.08071705361724A65.38461538461539,65.38461538461539,0,0,1,64.33850481299886,-11.649237203431182A65.38461538461539,65.38461538461539,0,0,0,61.54339821127998,-22.08071705361724Z"
            transform="rotate(-5)"
          />
          <path
            d="M47.01891876750695,-7.986081075993032A47.69230769230769,47.69230769230769,0,0,1,47.69136416603861,-0.29999500002499996A47.69230769230769,47.69230769230769,0,0,0,47.01891876750695,-7.986081075993032Z"
            transform="rotate(-5)"
          />
          <path
            d="M153.84586135562566,0.29999500002499996A153.84615384615384,153.84615384615384,0,0,1,151.56069061692494,26.419616064114106L81.8663581061254,14.130624839993011A83.07692307692307,83.07692307692307,0,0,0,83.07638142654596,0.29999500002499996Z"
            transform="rotate(-5)"
          />
          <path
            d="M64.33850481299886,11.649237203431182A65.38461538461539,65.38461538461539,0,0,1,61.54339821127998,22.08071705361724L44.91782731494551,16.02950411966903A47.69230769230769,47.69230769230769,0,0,0,46.91473159737989,8.576955879772067Z"
            transform="rotate(-5)"
          />
          <path
            d="M28.086764763486656,10.541994361626474A30,30,0,0,1,26.129460586265267,14.739446715220012A30,30,0,0,0,28.086764763486656,10.541994361626474Z"
            transform="rotate(-5)"
          />
          <path
            d="M56.47414442722144,32.951766875350046A65.38461538461539,65.38461538461539,0,0,1,50.27982714581678,41.79816875391635A65.38461538461539,65.38461538461539,0,0,0,56.47414442722144,32.951766875350046Z"
            transform="rotate(-5)"
          />
          <path
            d="M49.894161007848794,42.257787759381635A65.38461538461539,65.38461538461539,0,0,1,42.257787759381635,49.89416100784879L30.88522747771087,36.34087143517344A47.69230769230769,47.69230769230769,0,0,0,36.34087143517345,30.885227477710863Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.79816875391637,50.27982714581676A65.38461538461539,65.38461538461539,0,0,1,32.951766875350074,56.474144427221425A65.38461538461539,65.38461538461539,0,0,0,41.79816875391637,50.27982714581676Z"
            transform="rotate(-5)"
          />
          <path
            d="M14.739446715220014,26.129460586265267A30,30,0,0,1,10.541994361626475,28.086764763486656A30,30,0,0,0,14.739446715220014,26.129460586265267Z"
            transform="rotate(-5)"
          />
          <path
            d="M16.029504119669028,44.91782731494551A47.69230769230769,47.69230769230769,0,0,1,8.576955879772084,46.91473159737989L5.504622261801532,29.490661805983244A30,30,0,0,0,9.978188186034153,28.291973429297727Z"
            transform="rotate(-5)"
          />
          <path
            d="M20.275126913914868,116.71356100856148A118.46153846153847,118.46153846153847,0,0,1,0.2999950000250499,118.46115860346174L0.2999950000250522,100.76878421824115A100.76923076923077,100.76923076923077,0,0,0,17.202878343320215,99.28997354480164Z"
            transform="rotate(-5)"
          />
          <path
            d="M-0.29999500002497775,153.84586135562566A153.84615384615384,153.84615384615384,0,0,1,-26.419616064114077,151.56069061692494L-14.130624839992988,81.86635810612542A83.07692307692307,83.07692307692307,0,0,0,-0.29999500002499474,83.07638142654596Z"
            transform="rotate(-5)"
          />
          <path
            d="M-8.576955879772058,46.91473159737989A47.69230769230769,47.69230769230769,0,0,1,-16.029504119669,44.91782731494552A47.69230769230769,47.69230769230769,0,0,0,-8.576955879772058,46.91473159737989Z"
            transform="rotate(-5)"
          />
          <path
            d="M-10.541994361626472,28.086764763486656A30,30,0,0,1,-14.739446715220016,26.129460586265264A30,30,0,0,0,-10.541994361626472,28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M-112.56739543078369,194.37245816176411A224.6153846153846,224.6153846153846,0,0,1,-144.15004789959346,172.25804682644602L-98.66040397821669,118.0456002573138A153.84615384615384,153.84615384615384,0,0,0,-77.18273396884275,133.08442670105802Z"
            transform="rotate(-5)"
          />
          <path
            d="M-53.63027814130934,63.44736727725422A83.07692307692307,83.07692307692307,0,0,1,-63.447367277254216,53.63027814130935L-36.34087143517344,30.885227477710874A47.69230769230769,47.69230769230769,0,0,0,-30.88522747771087,36.34087143517344Z"
            transform="rotate(-5)"
          />
          <path
            d="M-63.833033415222204,53.17065913584407A83.07692307692307,83.07692307692307,0,0,1,-71.79625926986198,41.797994004303L-56.474144427221425,32.951766875350074A65.38461538461539,65.38461538461539,0,0,0,-50.27982714581676,41.79816875391638Z"
            transform="rotate(-5)"
          />
          <path
            d="M-102.74037021234787,58.97077601070094A118.46153846153847,118.46153846153847,0,0,1,-111.21447225651656,40.798005531877L-77.9636582552152,28.6956989702814A83.07692307692307,83.07692307692307,0,0,0,-72.09625426988701,41.278387422243036Z"
            transform="rotate(-5)"
          />
          <path
            d="M-61.543398211279964,22.080717053617274A65.38461538461539,65.38461538461539,0,0,1,-64.33850481299885,11.649237203431236L-29.490661805983244,5.50462226180154A30,30,0,0,0,-28.291973429297716,9.978188186034172Z"
            transform="rotate(-5)"
          />
          <path
            d="M-168.98424210991234,29.491858292091052A171.53846153846155,171.53846153846155,0,0,1,-171.53819921516669,0.2999950000249935L-136.15351565598726,0.2999950000250227A136.15384615384616,136.15384615384616,0,0,0,-134.13713140294885,23.347372474718526Z"
            transform="rotate(-5)"
          />
          <path
            d="M-189.2305314336685,-0.29999500002494894A189.23076923076923,189.23076923076923,0,0,1,-186.40778804756062,-32.56409954051176L-134.13713140294885,-23.34737247471849A136.15384615384616,136.15384615384616,0,0,0,-136.15351565598726,-0.29999500002498936Z"
            transform="rotate(-5)"
          />
          <path
            d="M-134.0329442328218,-23.93824727849754A136.15384615384616,136.15384615384616,0,0,1,-128.04505828889546,-46.285341851158215L-44.91782731494553,-16.029504119669A47.69230769230769,47.69230769230769,0,0,0,-46.91473159737989,-8.576955879772054Z"
            transform="rotate(-5)"
          />
          <path
            d="M-61.33818954546893,-22.644523229209533A65.38461538461539,65.38461538461539,0,0,1,-56.77413942724647,-32.43216029329008L-26.12946058626527,-14.739446715220001A30,30,0,0,0,-28.086764763486666,-10.541994361626452Z"
            transform="rotate(-5)"
          />
          <path
            d="M-56.47414442722143,-32.95176687535006A65.38461538461539,65.38461538461539,0,0,1,-50.27982714581677,-41.798168753916364L-23.173017305464167,-19.052854614483845A30,30,0,0,0,-25.829465586240264,-15.25905329727994Z"
            transform="rotate(-5)"
          />
          <path
            d="M-36.34087143517345,-30.885227477710863A47.69230769230769,47.69230769230769,0,0,1,-30.885227477710874,-36.34087143517344L-19.5124736199491,-22.787351167496205A30,30,0,0,0,-22.787351167496208,-19.51247361994909Z"
            transform="rotate(-5)"
          />
          <path
            d="M-75.91555547668457,-90.93934534260175A118.46153846153847,118.46153846153847,0,0,1,-59.49038259276089,-102.44037521232285L-24.10548537404929,-41.15193540891178A47.69230769230769,47.69230769230769,0,0,0,-30.425608472245614,-36.72653757314141Z"
            transform="rotate(-5)"
          />
          <path
            d="M-32.432160293290174,-56.7741394272464A65.38461538461539,65.38461538461539,0,0,1,-22.644523229209575,-61.3381895454689L-10.541994361626484,-28.086764763486652A30,30,0,0,0,-14.739446715220033,-26.129460586265253Z"
            transform="rotate(-5)"
          />
          <path
            d="M-40.23419935628466,-111.41968092232763A118.46153846153847,118.46153846153847,0,0,1,-20.866001717693976,-116.6093738384344L-11.64923720343121,-64.33850481299886A65.38461538461539,65.38461538461539,0,0,0,-22.080717053617303,-61.54339821127996Z"
            transform="rotate(-5)"
          />
          <path
            d="M-7.986081075993025,-47.01891876750695A47.69230769230769,47.69230769230769,0,0,1,-0.2999950000250128,-47.69136416603861L-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-4.913747458022494,-29.59484897611031Z"
            transform="rotate(-5)"
          />
        </g>
        <g fill="#6baed6">
          <path
            d="M0.29999500002499657,-153.84586135562566A153.84615384615384,153.84615384615384,0,0,1,26.419616064114127,-151.56069061692494L23.347372474718547,-134.13713140294885A136.15384615384616,136.15384615384616,0,0,0,0.29999500002501434,-136.15351565598726Z"
            transform="rotate(-5)"
          />
          <path
            d="M30.082733095870097,-168.88005493978528A171.53846153846155,171.53846153846155,0,0,1,58.387616393602265,-161.29578431830078L52.33648046311592,-144.6704249872389A153.84615384615384,153.84615384615384,0,0,0,27.01049086789314,-151.45650344679788Z"
            transform="rotate(-5)"
          />
          <path
            d="M10.541994361626475,-28.086764763486656A30,30,0,0,1,14.739446715220014,-26.129460586265267A30,30,0,0,0,10.541994361626475,-28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M24.105485374049266,-41.1519354089118A47.69230769230769,47.69230769230769,0,0,1,30.425608472245607,-36.726537573141414A47.69230769230769,47.69230769230769,0,0,0,24.105485374049266,-41.1519354089118Z"
            transform="rotate(-5)"
          />
          <path
            d="M65.00273544139452,-77.00053412125501A100.76923076923077,100.76923076923077,0,0,1,77.00053412125501,-65.00273544139452L63.44736727725424,-53.630278141309326A83.07692307692307,83.07692307692307,0,0,0,53.630278141309326,-63.44736727725424Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.833033415222204,-53.17065913584407A83.07692307692307,83.07692307692307,0,0,1,71.796259269862,-41.79799400430295A83.07692307692307,83.07692307692307,0,0,0,63.833033415222204,-53.17065913584407Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.45193040893679,-23.585878791989344A47.69230769230769,47.69230769230769,0,0,1,44.71261864913445,-16.59331029526135A47.69230769230769,47.69230769230769,0,0,0,41.45193040893679,-23.585878791989344Z"
            transform="rotate(-5)"
          />
          <path
            d="M61.54339821127998,-22.08071705361724A65.38461538461539,65.38461538461539,0,0,1,64.33850481299886,-11.649237203431182A65.38461538461539,65.38461538461539,0,0,0,61.54339821127998,-22.08071705361724Z"
            transform="rotate(-5)"
          />
          <path
            d="M47.01891876750695,-7.986081075993032A47.69230769230769,47.69230769230769,0,0,1,47.69136416603861,-0.29999500002499996A47.69230769230769,47.69230769230769,0,0,0,47.01891876750695,-7.986081075993032Z"
            transform="rotate(-5)"
          />
          <path
            d="M153.84586135562566,0.29999500002499996A153.84615384615384,153.84615384615384,0,0,1,151.56069061692494,26.419616064114106A153.84615384615384,153.84615384615384,0,0,0,153.84586135562566,0.29999500002499996Z"
            transform="rotate(-5)"
          />
          <path
            d="M64.33850481299886,11.649237203431182A65.38461538461539,65.38461538461539,0,0,1,61.54339821127998,22.08071705361724A65.38461538461539,65.38461538461539,0,0,0,64.33850481299886,11.649237203431182Z"
            transform="rotate(-5)"
          />
          <path
            d="M61.338189545468914,22.64452322920956A65.38461538461539,65.38461538461539,0,0,1,56.774139427246446,32.43216029329012L26.129460586265267,14.739446715220012A30,30,0,0,0,28.086764763486656,10.541994361626474Z"
            transform="rotate(-5)"
          />
          <path
            d="M56.47414442722144,32.951766875350046A65.38461538461539,65.38461538461539,0,0,1,50.27982714581678,41.79816875391635A65.38461538461539,65.38461538461539,0,0,0,56.47414442722144,32.951766875350046Z"
            transform="rotate(-5)"
          />
          <path
            d="M49.894161007848794,42.257787759381635A65.38461538461539,65.38461538461539,0,0,1,42.257787759381635,49.89416100784879A65.38461538461539,65.38461538461539,0,0,0,49.894161007848794,42.257787759381635Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.79816875391637,50.27982714581676A65.38461538461539,65.38461538461539,0,0,1,32.951766875350074,56.474144427221425A65.38461538461539,65.38461538461539,0,0,0,41.79816875391637,50.27982714581676Z"
            transform="rotate(-5)"
          />
          <path
            d="M14.739446715220014,26.129460586265267A30,30,0,0,1,10.541994361626475,28.086764763486656A30,30,0,0,0,14.739446715220014,26.129460586265267Z"
            transform="rotate(-5)"
          />
          <path
            d="M22.08071705361724,61.54339821127998A65.38461538461539,65.38461538461539,0,0,1,11.649237203431204,64.33850481299886L8.576955879772084,46.91473159737989A47.69230769230769,47.69230769230769,0,0,0,16.029504119669028,44.91782731494551Z"
            transform="rotate(-5)"
          />
          <path
            d="M23.347372474718576,134.13713140294882A136.15384615384616,136.15384615384616,0,0,1,0.2999950000250748,136.15351565598726L0.2999950000250499,118.46115860346174A118.46153846153847,118.46153846153847,0,0,0,20.275126913914868,116.71356100856148Z"
            transform="rotate(-5)"
          />
          <path
            d="M-0.29999500002497775,153.84586135562566A153.84615384615384,153.84615384615384,0,0,1,-26.419616064114077,151.56069061692494A153.84615384615384,153.84615384615384,0,0,0,-0.29999500002497775,153.84586135562566Z"
            transform="rotate(-5)"
          />
          <path
            d="M-8.576955879772058,46.91473159737989A47.69230769230769,47.69230769230769,0,0,1,-16.029504119669,44.91782731494552A47.69230769230769,47.69230769230769,0,0,0,-8.576955879772058,46.91473159737989Z"
            transform="rotate(-5)"
          />
          <path
            d="M-10.541994361626472,28.086764763486656A30,30,0,0,1,-14.739446715220016,26.129460586265264A30,30,0,0,0,-10.541994361626472,28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M-112.56739543078369,194.37245816176411A224.6153846153846,224.6153846153846,0,0,1,-144.15004789959346,172.25804682644602A224.6153846153846,224.6153846153846,0,0,0,-112.56739543078369,194.37245816176411Z"
            transform="rotate(-5)"
          />
          <path
            d="M-76.37517448214984,90.55367920463378A118.46153846153847,118.46153846153847,0,0,1,-90.55367920463377,76.37517448214986L-63.447367277254216,53.63027814130935A83.07692307692307,83.07692307692307,0,0,0,-53.63027814130934,63.44736727725422Z"
            transform="rotate(-5)"
          />
          <path
            d="M-118.04560025731372,98.66040397821676A153.84615384615384,153.84615384615384,0,0,1,-133.08442670105796,77.18273396884284L-71.79625926986198,41.797994004303A83.07692307692307,83.07692307692307,0,0,0,-63.833033415222204,53.17065913584407Z"
            transform="rotate(-5)"
          />
          <path
            d="M-118.06240087265974,67.8169545369637A136.15384615384616,136.15384615384616,0,0,1,-127.8398496230843,46.84914802675069L-111.21447225651656,40.798005531877A118.46153846153847,118.46153846153847,0,0,0,-102.74037021234787,58.97077601070094Z"
            transform="rotate(-5)"
          />
          <path
            d="M-61.543398211279964,22.080717053617274A65.38461538461539,65.38461538461539,0,0,1,-64.33850481299885,11.649237203431236A65.38461538461539,65.38461538461539,0,0,0,-61.543398211279964,22.080717053617274Z"
            transform="rotate(-5)"
          />
          <path
            d="M-186.40778804756062,32.56409954051181A189.23076923076923,189.23076923076923,0,0,1,-189.2305314336685,0.2999950000249953L-171.53819921516669,0.2999950000249935A171.53846153846155,171.53846153846155,0,0,0,-168.98424210991234,29.491858292091052Z"
            transform="rotate(-5)"
          />
          <path
            d="M-189.2305314336685,-0.29999500002494894A189.23076923076923,189.23076923076923,0,0,1,-186.40778804756062,-32.56409954051176A189.23076923076923,189.23076923076923,0,0,0,-189.2305314336685,-0.29999500002494894Z"
            transform="rotate(-5)"
          />
          <path
            d="M-134.0329442328218,-23.93824727849754A136.15384615384616,136.15384615384616,0,0,1,-128.04505828889546,-46.285341851158215A136.15384615384616,136.15384615384616,0,0,0,-134.0329442328218,-23.93824727849754Z"
            transform="rotate(-5)"
          />
          <path
            d="M-77.96365825521521,-28.695698970281313A83.07692307692307,83.07692307692307,0,0,1,-72.09625426988705,-41.27838742224295L-56.77413942724647,-32.43216029329008A65.38461538461539,65.38461538461539,0,0,0,-61.33818954546893,-22.644523229209533Z"
            transform="rotate(-5)"
          />
          <path
            d="M-71.796259269862,-41.79799400430298A83.07692307692307,83.07692307692307,0,0,1,-63.83303341522221,-53.17065913584405L-50.27982714581677,-41.798168753916364A65.38461538461539,65.38461538461539,0,0,0,-56.47414442722143,-32.95176687535006Z"
            transform="rotate(-5)"
          />
          <path
            d="M-49.894161007848794,-42.25778775938163A65.38461538461539,65.38461538461539,0,0,1,-42.25778775938162,-49.8941610078488L-30.885227477710874,-36.34087143517344A47.69230769230769,47.69230769230769,0,0,0,-36.34087143517345,-30.885227477710863Z"
            transform="rotate(-5)"
          />
          <path
            d="M-87.28798337619826,-104.4924771483658A136.15384615384616,136.15384615384616,0,0,1,-68.33656111902366,-117.76240587263472L-59.49038259276089,-102.44037521232285A118.46153846153847,118.46153846153847,0,0,0,-75.91555547668457,-90.93934534260175Z"
            transform="rotate(-5)"
          />
          <path
            d="M-41.27838742224304,-72.096254269887A83.07692307692307,83.07692307692307,0,0,1,-28.695698970281406,-77.9636582552152L-22.644523229209575,-61.3381895454689A65.38461538461539,65.38461538461539,0,0,0,-32.432160293290174,-56.7741394272464Z"
            transform="rotate(-5)"
          />
          <path
            d="M-40.23419935628466,-111.41968092232763A118.46153846153847,118.46153846153847,0,0,1,-20.866001717693976,-116.6093738384344A118.46153846153847,118.46153846153847,0,0,0,-40.23419935628466,-111.41968092232763Z"
            transform="rotate(-5)"
          />
          <path
            d="M-7.986081075993025,-47.01891876750695A47.69230769230769,47.69230769230769,0,0,1,-0.2999950000250128,-47.69136416603861A47.69230769230769,47.69230769230769,0,0,0,-7.986081075993025,-47.01891876750695Z"
            transform="rotate(-5)"
          />
        </g>
        <g fill="#4292c6">
          <path
            d="M0.29999500002499657,-153.84586135562566A153.84615384615384,153.84615384615384,0,0,1,26.419616064114127,-151.56069061692494A153.84615384615384,153.84615384615384,0,0,0,0.29999500002499657,-153.84586135562566Z"
            transform="rotate(-5)"
          />
          <path
            d="M30.082733095870097,-168.88005493978528A171.53846153846155,171.53846153846155,0,0,1,58.387616393602265,-161.29578431830078A171.53846153846155,171.53846153846155,0,0,0,30.082733095870097,-168.88005493978528Z"
            transform="rotate(-5)"
          />
          <path
            d="M10.541994361626475,-28.086764763486656A30,30,0,0,1,14.739446715220014,-26.129460586265267A30,30,0,0,0,10.541994361626475,-28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M24.105485374049266,-41.1519354089118A47.69230769230769,47.69230769230769,0,0,1,30.425608472245607,-36.726537573141414A47.69230769230769,47.69230769230769,0,0,0,24.105485374049266,-41.1519354089118Z"
            transform="rotate(-5)"
          />
          <path
            d="M65.00273544139452,-77.00053412125501A100.76923076923077,100.76923076923077,0,0,1,77.00053412125501,-65.00273544139452A100.76923076923077,100.76923076923077,0,0,0,65.00273544139452,-77.00053412125501Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.833033415222204,-53.17065913584407A83.07692307692307,83.07692307692307,0,0,1,71.796259269862,-41.79799400430295A83.07692307692307,83.07692307692307,0,0,0,63.833033415222204,-53.17065913584407Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.45193040893679,-23.585878791989344A47.69230769230769,47.69230769230769,0,0,1,44.71261864913445,-16.59331029526135A47.69230769230769,47.69230769230769,0,0,0,41.45193040893679,-23.585878791989344Z"
            transform="rotate(-5)"
          />
          <path
            d="M61.54339821127998,-22.08071705361724A65.38461538461539,65.38461538461539,0,0,1,64.33850481299886,-11.649237203431182A65.38461538461539,65.38461538461539,0,0,0,61.54339821127998,-22.08071705361724Z"
            transform="rotate(-5)"
          />
          <path
            d="M47.01891876750695,-7.986081075993032A47.69230769230769,47.69230769230769,0,0,1,47.69136416603861,-0.29999500002499996A47.69230769230769,47.69230769230769,0,0,0,47.01891876750695,-7.986081075993032Z"
            transform="rotate(-5)"
          />
          <path
            d="M153.84586135562566,0.29999500002499996A153.84615384615384,153.84615384615384,0,0,1,151.56069061692494,26.419616064114106A153.84615384615384,153.84615384615384,0,0,0,153.84586135562566,0.29999500002499996Z"
            transform="rotate(-5)"
          />
          <path
            d="M64.33850481299886,11.649237203431182A65.38461538461539,65.38461538461539,0,0,1,61.54339821127998,22.08071705361724A65.38461538461539,65.38461538461539,0,0,0,64.33850481299886,11.649237203431182Z"
            transform="rotate(-5)"
          />
          <path
            d="M111.21447225651659,40.798005531876925A118.46153846153847,118.46153846153847,0,0,1,102.7403702123479,58.97077601070089L56.774139427246446,32.43216029329012A65.38461538461539,65.38461538461539,0,0,0,61.338189545468914,22.64452322920956Z"
            transform="rotate(-5)"
          />
          <path
            d="M56.47414442722144,32.951766875350046A65.38461538461539,65.38461538461539,0,0,1,50.27982714581678,41.79816875391635A65.38461538461539,65.38461538461539,0,0,0,56.47414442722144,32.951766875350046Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.44736727725424,53.63027814130933A83.07692307692307,83.07692307692307,0,0,1,53.63027814130933,63.44736727725422L42.257787759381635,49.89416100784879A65.38461538461539,65.38461538461539,0,0,0,49.894161007848794,42.257787759381635Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.79816875391637,50.27982714581676A65.38461538461539,65.38461538461539,0,0,1,32.951766875350074,56.474144427221425A65.38461538461539,65.38461538461539,0,0,0,41.79816875391637,50.27982714581676Z"
            transform="rotate(-5)"
          />
          <path
            d="M23.585878791989334,41.451930408936796A47.69230769230769,47.69230769230769,0,0,1,16.593310295261357,44.71261864913444L10.541994361626475,28.086764763486656A30,30,0,0,0,14.739446715220014,26.129460586265267Z"
            transform="rotate(-5)"
          />
          <path
            d="M22.08071705361724,61.54339821127998A65.38461538461539,65.38461538461539,0,0,1,11.649237203431204,64.33850481299886A65.38461538461539,65.38461538461539,0,0,0,22.08071705361724,61.54339821127998Z"
            transform="rotate(-5)"
          />
          <path
            d="M29.491858292091077,168.98424210991234A171.53846153846155,171.53846153846155,0,0,1,0.29999500002509727,171.53819921516669L0.2999950000250748,136.15351565598726A136.15384615384616,136.15384615384616,0,0,0,23.347372474718576,134.13713140294882Z"
            transform="rotate(-5)"
          />
          <path
            d="M-0.29999500002497775,153.84586135562566A153.84615384615384,153.84615384615384,0,0,1,-26.419616064114077,151.56069061692494A153.84615384615384,153.84615384615384,0,0,0,-0.29999500002497775,153.84586135562566Z"
            transform="rotate(-5)"
          />
          <path
            d="M-8.576955879772058,46.91473159737989A47.69230769230769,47.69230769230769,0,0,1,-16.029504119669,44.91782731494552A47.69230769230769,47.69230769230769,0,0,0,-8.576955879772058,46.91473159737989Z"
            transform="rotate(-5)"
          />
          <path
            d="M-10.541994361626472,28.086764763486656A30,30,0,0,1,-14.739446715220016,26.129460586265264A30,30,0,0,0,-10.541994361626472,28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M-112.56739543078369,194.37245816176411A224.6153846153846,224.6153846153846,0,0,1,-144.15004789959346,172.25804682644602A224.6153846153846,224.6153846153846,0,0,0,-112.56739543078369,194.37245816176411Z"
            transform="rotate(-5)"
          />
          <path
            d="M-76.37517448214984,90.55367920463378A118.46153846153847,118.46153846153847,0,0,1,-90.55367920463377,76.37517448214986A118.46153846153847,118.46153846153847,0,0,0,-76.37517448214984,90.55367920463378Z"
            transform="rotate(-5)"
          />
          <path
            d="M-118.04560025731372,98.66040397821676A153.84615384615384,153.84615384615384,0,0,1,-133.08442670105796,77.18273396884284A153.84615384615384,153.84615384615384,0,0,0,-118.04560025731372,98.66040397821676Z"
            transform="rotate(-5)"
          />
          <path
            d="M-118.06240087265974,67.8169545369637A136.15384615384616,136.15384615384616,0,0,1,-127.8398496230843,46.84914802675069A136.15384615384616,136.15384615384616,0,0,0,-118.06240087265974,67.8169545369637Z"
            transform="rotate(-5)"
          />
          <path
            d="M-61.543398211279964,22.080717053617274A65.38461538461539,65.38461538461539,0,0,1,-64.33850481299885,11.649237203431236A65.38461538461539,65.38461538461539,0,0,0,-61.543398211279964,22.080717053617274Z"
            transform="rotate(-5)"
          />
          <path
            d="M-203.83132985484872,35.63634006063856A206.92307692307693,206.92307692307693,0,0,1,-206.9228594580927,0.29999500002506363L-189.2305314336685,0.2999950000249953A189.23076923076923,189.23076923076923,0,0,0,-186.40778804756062,32.56409954051181Z"
            transform="rotate(-5)"
          />
          <path
            d="M-189.2305314336685,-0.29999500002494894A189.23076923076923,189.23076923076923,0,0,1,-186.40778804756062,-32.56409954051176A189.23076923076923,189.23076923076923,0,0,0,-189.2305314336685,-0.29999500002494894Z"
            transform="rotate(-5)"
          />
          <path
            d="M-134.0329442328218,-23.93824727849754A136.15384615384616,136.15384615384616,0,0,1,-128.04505828889546,-46.285341851158215A136.15384615384616,136.15384615384616,0,0,0,-134.0329442328218,-23.93824727849754Z"
            transform="rotate(-5)"
          />
          <path
            d="M-77.96365825521521,-28.695698970281313A83.07692307692307,83.07692307692307,0,0,1,-72.09625426988705,-41.27838742224295A83.07692307692307,83.07692307692307,0,0,0,-77.96365825521521,-28.695698970281313Z"
            transform="rotate(-5)"
          />
          <path
            d="M-71.796259269862,-41.79799400430298A83.07692307692307,83.07692307692307,0,0,1,-63.83303341522221,-53.17065913584405A83.07692307692307,83.07692307692307,0,0,0,-71.796259269862,-41.79799400430298Z"
            transform="rotate(-5)"
          />
          <path
            d="M-49.894161007848794,-42.25778775938163A65.38461538461539,65.38461538461539,0,0,1,-42.25778775938162,-49.8941610078488A65.38461538461539,65.38461538461539,0,0,0,-49.894161007848794,-42.25778775938163Z"
            transform="rotate(-5)"
          />
          <path
            d="M-87.28798337619826,-104.4924771483658A136.15384615384616,136.15384615384616,0,0,1,-68.33656111902366,-117.76240587263472A136.15384615384616,136.15384615384616,0,0,0,-87.28798337619826,-104.4924771483658Z"
            transform="rotate(-5)"
          />
          <path
            d="M-41.27838742224304,-72.096254269887A83.07692307692307,83.07692307692307,0,0,1,-28.695698970281406,-77.9636582552152A83.07692307692307,83.07692307692307,0,0,0,-41.27838742224304,-72.096254269887Z"
            transform="rotate(-5)"
          />
          <path
            d="M-40.23419935628466,-111.41968092232763A118.46153846153847,118.46153846153847,0,0,1,-20.866001717693976,-116.6093738384344A118.46153846153847,118.46153846153847,0,0,0,-40.23419935628466,-111.41968092232763Z"
            transform="rotate(-5)"
          />
          <path
            d="M-7.986081075993025,-47.01891876750695A47.69230769230769,47.69230769230769,0,0,1,-0.2999950000250128,-47.69136416603861A47.69230769230769,47.69230769230769,0,0,0,-7.986081075993025,-47.01891876750695Z"
            transform="rotate(-5)"
          />
        </g>
        <g fill="#2171b5">
          <path
            d="M0.29999500002499657,-153.84586135562566A153.84615384615384,153.84615384615384,0,0,1,26.419616064114127,-151.56069061692494A153.84615384615384,153.84615384615384,0,0,0,0.29999500002499657,-153.84586135562566Z"
            transform="rotate(-5)"
          />
          <path
            d="M30.082733095870097,-168.88005493978528A171.53846153846155,171.53846153846155,0,0,1,58.387616393602265,-161.29578431830078A171.53846153846155,171.53846153846155,0,0,0,30.082733095870097,-168.88005493978528Z"
            transform="rotate(-5)"
          />
          <path
            d="M10.541994361626475,-28.086764763486656A30,30,0,0,1,14.739446715220014,-26.129460586265267A30,30,0,0,0,10.541994361626475,-28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M24.105485374049266,-41.1519354089118A47.69230769230769,47.69230769230769,0,0,1,30.425608472245607,-36.726537573141414A47.69230769230769,47.69230769230769,0,0,0,24.105485374049266,-41.1519354089118Z"
            transform="rotate(-5)"
          />
          <path
            d="M65.00273544139452,-77.00053412125501A100.76923076923077,100.76923076923077,0,0,1,77.00053412125501,-65.00273544139452A100.76923076923077,100.76923076923077,0,0,0,65.00273544139452,-77.00053412125501Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.833033415222204,-53.17065913584407A83.07692307692307,83.07692307692307,0,0,1,71.796259269862,-41.79799400430295A83.07692307692307,83.07692307692307,0,0,0,63.833033415222204,-53.17065913584407Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.45193040893679,-23.585878791989344A47.69230769230769,47.69230769230769,0,0,1,44.71261864913445,-16.59331029526135A47.69230769230769,47.69230769230769,0,0,0,41.45193040893679,-23.585878791989344Z"
            transform="rotate(-5)"
          />
          <path
            d="M61.54339821127998,-22.08071705361724A65.38461538461539,65.38461538461539,0,0,1,64.33850481299886,-11.649237203431182A65.38461538461539,65.38461538461539,0,0,0,61.54339821127998,-22.08071705361724Z"
            transform="rotate(-5)"
          />
          <path
            d="M47.01891876750695,-7.986081075993032A47.69230769230769,47.69230769230769,0,0,1,47.69136416603861,-0.29999500002499996A47.69230769230769,47.69230769230769,0,0,0,47.01891876750695,-7.986081075993032Z"
            transform="rotate(-5)"
          />
          <path
            d="M153.84586135562566,0.29999500002499996A153.84615384615384,153.84615384615384,0,0,1,151.56069061692494,26.419616064114106A153.84615384615384,153.84615384615384,0,0,0,153.84586135562566,0.29999500002499996Z"
            transform="rotate(-5)"
          />
          <path
            d="M64.33850481299886,11.649237203431182A65.38461538461539,65.38461538461539,0,0,1,61.54339821127998,22.08071705361724A65.38461538461539,65.38461538461539,0,0,0,64.33850481299886,11.649237203431182Z"
            transform="rotate(-5)"
          />
          <path
            d="M111.21447225651659,40.798005531876925A118.46153846153847,118.46153846153847,0,0,1,102.7403702123479,58.97077601070089A118.46153846153847,118.46153846153847,0,0,0,111.21447225651659,40.798005531876925Z"
            transform="rotate(-5)"
          />
          <path
            d="M71.79625926986202,41.797994004302936A83.07692307692307,83.07692307692307,0,0,1,63.833033415222225,53.17065913584404L50.27982714581678,41.79816875391635A65.38461538461539,65.38461538461539,0,0,0,56.47414442722144,32.951766875350046Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.44736727725424,53.63027814130933A83.07692307692307,83.07692307692307,0,0,1,53.63027814130933,63.44736727725422A83.07692307692307,83.07692307692307,0,0,0,63.44736727725424,53.63027814130933Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.79816875391637,50.27982714581676A65.38461538461539,65.38461538461539,0,0,1,32.951766875350074,56.474144427221425A65.38461538461539,65.38461538461539,0,0,0,41.79816875391637,50.27982714581676Z"
            transform="rotate(-5)"
          />
          <path
            d="M23.585878791989334,41.451930408936796A47.69230769230769,47.69230769230769,0,0,1,16.593310295261357,44.71261864913444A47.69230769230769,47.69230769230769,0,0,0,23.585878791989334,41.451930408936796Z"
            transform="rotate(-5)"
          />
          <path
            d="M28.131892794689023,78.16886692102628A83.07692307692307,83.07692307692307,0,0,1,14.72149964377207,81.76217093599833L11.649237203431204,64.33850481299886A65.38461538461539,65.38461538461539,0,0,0,22.08071705361724,61.54339821127998Z"
            transform="rotate(-5)"
          />
          <path
            d="M29.491858292091077,168.98424210991234A171.53846153846155,171.53846153846155,0,0,1,0.29999500002509727,171.53819921516669A171.53846153846155,171.53846153846155,0,0,0,29.491858292091077,168.98424210991234Z"
            transform="rotate(-5)"
          />
          <path
            d="M-0.29999500002500007,171.53819921516669A171.53846153846155,171.53846153846155,0,0,1,-29.49185829209098,168.98424210991234L-26.419616064114077,151.56069061692494A153.84615384615384,153.84615384615384,0,0,0,-0.29999500002497775,153.84586135562566Z"
            transform="rotate(-5)"
          />
          <path
            d="M-11.649237203431168,64.33850481299886A65.38461538461539,65.38461538461539,0,0,1,-22.080717053617207,61.54339821127999L-16.029504119669,44.91782731494552A47.69230769230769,47.69230769230769,0,0,0,-8.576955879772058,46.91473159737989Z"
            transform="rotate(-5)"
          />
          <path
            d="M-16.59331029526135,44.71261864913445A47.69230769230769,47.69230769230769,0,0,1,-23.585878791989327,41.4519304089368L-14.739446715220016,26.129460586265264A30,30,0,0,0,-10.541994361626472,28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M-112.56739543078369,194.37245816176411A224.6153846153846,224.6153846153846,0,0,1,-144.15004789959346,172.25804682644602A224.6153846153846,224.6153846153846,0,0,0,-112.56739543078369,194.37245816176411Z"
            transform="rotate(-5)"
          />
          <path
            d="M-76.37517448214984,90.55367920463378A118.46153846153847,118.46153846153847,0,0,1,-90.55367920463377,76.37517448214986A118.46153846153847,118.46153846153847,0,0,0,-76.37517448214984,90.55367920463378Z"
            transform="rotate(-5)"
          />
          <path
            d="M-145.1518301421968,121.40523147722874A189.23076923076923,189.23076923076923,0,0,1,-163.72844989317414,94.87506900786424L-133.08442670105796,77.18273396884284A153.84615384615384,153.84615384615384,0,0,0,-118.04560025731372,98.66040397821676Z"
            transform="rotate(-5)"
          />
          <path
            d="M-133.38442170108294,76.66312738678293A153.84615384615384,153.84615384615384,0,0,1,-144.46521632142782,52.90028663870829L-127.8398496230843,46.84914802675069A136.15384615384616,136.15384615384616,0,0,0,-118.06240087265974,67.8169545369637Z"
            transform="rotate(-5)"
          />
          <path
            d="M-61.543398211279964,22.080717053617274A65.38461538461539,65.38461538461539,0,0,1,-64.33850481299885,11.649237203431236A65.38461538461539,65.38461538461539,0,0,0,-61.543398211279964,22.080717053617274Z"
            transform="rotate(-5)"
          />
          <path
            d="M-221.25486850778836,38.708580024568676A224.6153846153846,224.6153846153846,0,0,1,-224.6151842795075,0.29999500002504365L-206.9228594580927,0.29999500002506363A206.92307692307693,206.92307692307693,0,0,0,-203.83132985484872,35.63634006063856Z"
            transform="rotate(-5)"
          />
          <path
            d="M-189.2305314336685,-0.29999500002494894A189.23076923076923,189.23076923076923,0,0,1,-186.40778804756062,-32.56409954051176A189.23076923076923,189.23076923076923,0,0,0,-189.2305314336685,-0.29999500002494894Z"
            transform="rotate(-5)"
          />
          <path
            d="M-134.0329442328218,-23.93824727849754A136.15384615384616,136.15384615384616,0,0,1,-128.04505828889546,-46.285341851158215A136.15384615384616,136.15384615384616,0,0,0,-134.0329442328218,-23.93824727849754Z"
            transform="rotate(-5)"
          />
          <path
            d="M-77.96365825521521,-28.695698970281313A83.07692307692307,83.07692307692307,0,0,1,-72.09625426988705,-41.27838742224295A83.07692307692307,83.07692307692307,0,0,0,-77.96365825521521,-28.695698970281313Z"
            transform="rotate(-5)"
          />
          <path
            d="M-71.796259269862,-41.79799400430298A83.07692307692307,83.07692307692307,0,0,1,-63.83303341522221,-53.17065913584405A83.07692307692307,83.07692307692307,0,0,0,-71.796259269862,-41.79799400430298Z"
            transform="rotate(-5)"
          />
          <path
            d="M-49.894161007848794,-42.25778775938163A65.38461538461539,65.38461538461539,0,0,1,-42.25778775938162,-49.8941610078488A65.38461538461539,65.38461538461539,0,0,0,-49.894161007848794,-42.25778775938163Z"
            transform="rotate(-5)"
          />
          <path
            d="M-87.28798337619826,-104.4924771483658A136.15384615384616,136.15384615384616,0,0,1,-68.33656111902366,-117.76240587263472A136.15384615384616,136.15384615384616,0,0,0,-87.28798337619826,-104.4924771483658Z"
            transform="rotate(-5)"
          />
          <path
            d="M-41.27838742224304,-72.096254269887A83.07692307692307,83.07692307692307,0,0,1,-28.695698970281406,-77.9636582552152A83.07692307692307,83.07692307692307,0,0,0,-41.27838742224304,-72.096254269887Z"
            transform="rotate(-5)"
          />
          <path
            d="M-40.23419935628466,-111.41968092232763A118.46153846153847,118.46153846153847,0,0,1,-20.866001717693976,-116.6093738384344A118.46153846153847,118.46153846153847,0,0,0,-40.23419935628466,-111.41968092232763Z"
            transform="rotate(-5)"
          />
          <path
            d="M-7.986081075993025,-47.01891876750695A47.69230769230769,47.69230769230769,0,0,1,-0.2999950000250128,-47.69136416603861A47.69230769230769,47.69230769230769,0,0,0,-7.986081075993025,-47.01891876750695Z"
            transform="rotate(-5)"
          />
        </g>
        <g fill="#08519c">
          <path
            d="M0.29999500002499657,-153.84586135562566A153.84615384615384,153.84615384615384,0,0,1,26.419616064114127,-151.56069061692494A153.84615384615384,153.84615384615384,0,0,0,0.29999500002499657,-153.84586135562566Z"
            transform="rotate(-5)"
          />
          <path
            d="M30.082733095870097,-168.88005493978528A171.53846153846155,171.53846153846155,0,0,1,58.387616393602265,-161.29578431830078A171.53846153846155,171.53846153846155,0,0,0,30.082733095870097,-168.88005493978528Z"
            transform="rotate(-5)"
          />
          <path
            d="M10.541994361626475,-28.086764763486656A30,30,0,0,1,14.739446715220014,-26.129460586265267A30,30,0,0,0,10.541994361626475,-28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M24.105485374049266,-41.1519354089118A47.69230769230769,47.69230769230769,0,0,1,30.425608472245607,-36.726537573141414A47.69230769230769,47.69230769230769,0,0,0,24.105485374049266,-41.1519354089118Z"
            transform="rotate(-5)"
          />
          <path
            d="M65.00273544139452,-77.00053412125501A100.76923076923077,100.76923076923077,0,0,1,77.00053412125501,-65.00273544139452A100.76923076923077,100.76923076923077,0,0,0,65.00273544139452,-77.00053412125501Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.833033415222204,-53.17065913584407A83.07692307692307,83.07692307692307,0,0,1,71.796259269862,-41.79799400430295A83.07692307692307,83.07692307692307,0,0,0,63.833033415222204,-53.17065913584407Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.45193040893679,-23.585878791989344A47.69230769230769,47.69230769230769,0,0,1,44.71261864913445,-16.59331029526135A47.69230769230769,47.69230769230769,0,0,0,41.45193040893679,-23.585878791989344Z"
            transform="rotate(-5)"
          />
          <path
            d="M61.54339821127998,-22.08071705361724A65.38461538461539,65.38461538461539,0,0,1,64.33850481299886,-11.649237203431182A65.38461538461539,65.38461538461539,0,0,0,61.54339821127998,-22.08071705361724Z"
            transform="rotate(-5)"
          />
          <path
            d="M47.01891876750695,-7.986081075993032A47.69230769230769,47.69230769230769,0,0,1,47.69136416603861,-0.29999500002499996A47.69230769230769,47.69230769230769,0,0,0,47.01891876750695,-7.986081075993032Z"
            transform="rotate(-5)"
          />
          <path
            d="M153.84586135562566,0.29999500002499996A153.84615384615384,153.84615384615384,0,0,1,151.56069061692494,26.419616064114106A153.84615384615384,153.84615384615384,0,0,0,153.84586135562566,0.29999500002499996Z"
            transform="rotate(-5)"
          />
          <path
            d="M64.33850481299886,11.649237203431182A65.38461538461539,65.38461538461539,0,0,1,61.54339821127998,22.08071705361724A65.38461538461539,65.38461538461539,0,0,0,64.33850481299886,11.649237203431182Z"
            transform="rotate(-5)"
          />
          <path
            d="M111.21447225651659,40.798005531876925A118.46153846153847,118.46153846153847,0,0,1,102.7403702123479,58.97077601070089A118.46153846153847,118.46153846153847,0,0,0,111.21447225651659,40.798005531876925Z"
            transform="rotate(-5)"
          />
          <path
            d="M71.79625926986202,41.797994004302936A83.07692307692307,83.07692307692307,0,0,1,63.833033415222225,53.17065913584404A83.07692307692307,83.07692307692307,0,0,0,71.79625926986202,41.797994004302936Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.44736727725424,53.63027814130933A83.07692307692307,83.07692307692307,0,0,1,53.63027814130933,63.44736727725422A83.07692307692307,83.07692307692307,0,0,0,63.44736727725424,53.63027814130933Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.79816875391637,50.27982714581676A65.38461538461539,65.38461538461539,0,0,1,32.951766875350074,56.474144427221425A65.38461538461539,65.38461538461539,0,0,0,41.79816875391637,50.27982714581676Z"
            transform="rotate(-5)"
          />
          <path
            d="M23.585878791989334,41.451930408936796A47.69230769230769,47.69230769230769,0,0,1,16.593310295261357,44.71261864913444A47.69230769230769,47.69230769230769,0,0,0,23.585878791989334,41.451930408936796Z"
            transform="rotate(-5)"
          />
          <path
            d="M40.23419935628462,111.41968092232764A118.46153846153847,118.46153846153847,0,0,1,20.866001717693884,116.60937383843442L14.72149964377207,81.76217093599833A83.07692307692307,83.07692307692307,0,0,0,28.131892794689023,78.16886692102628Z"
            transform="rotate(-5)"
          />
          <path
            d="M32.56409954051184,186.40778804756062A189.23076923076923,189.23076923076923,0,0,1,0.29999500002510976,189.2305314336685L0.29999500002509727,171.53819921516669A171.53846153846155,171.53846153846155,0,0,0,29.491858292091077,168.98424210991234Z"
            transform="rotate(-5)"
          />
          <path
            d="M-0.29999500002500007,171.53819921516669A171.53846153846155,171.53846153846155,0,0,1,-29.49185829209098,168.98424210991234A171.53846153846155,171.53846153846155,0,0,0,-0.29999500002500007,171.53819921516669Z"
            transform="rotate(-5)"
          />
          <path
            d="M-17.7937531470992,99.18578637467459A100.76923076923077,100.76923076923077,0,0,1,-34.183050933280015,94.79428726835427L-22.080717053617207,61.54339821127999A65.38461538461539,65.38461538461539,0,0,0,-11.649237203431168,64.33850481299886Z"
            transform="rotate(-5)"
          />
          <path
            d="M-16.59331029526135,44.71261864913445A47.69230769230769,47.69230769230769,0,0,1,-23.585878791989327,41.4519304089368A47.69230769230769,47.69230769230769,0,0,0,-16.59331029526135,44.71261864913445Z"
            transform="rotate(-5)"
          />
          <path
            d="M-112.56739543078369,194.37245816176411A224.6153846153846,224.6153846153846,0,0,1,-144.15004789959346,172.25804682644602A224.6153846153846,224.6153846153846,0,0,0,-112.56739543078369,194.37245816176411Z"
            transform="rotate(-5)"
          />
          <path
            d="M-76.37517448214984,90.55367920463378A118.46153846153847,118.46153846153847,0,0,1,-90.55367920463377,76.37517448214986A118.46153846153847,118.46153846153847,0,0,0,-76.37517448214984,90.55367920463378Z"
            transform="rotate(-5)"
          />
          <path
            d="M-158.70493971114513,132.77764071783847A206.92307692307693,206.92307692307693,0,0,1,-179.0504554144128,103.72123302007641L-163.72844989317414,94.87506900786424A189.23076923076923,189.23076923076923,0,0,0,-145.1518301421968,121.40523147722874Z"
            transform="rotate(-5)"
          />
          <path
            d="M-148.70643573978265,85.50929631655346A171.53846153846155,171.53846153846155,0,0,1,-161.0905756524897,58.951422569194655L-144.46521632142782,52.90028663870829A153.84615384615384,153.84615384615384,0,0,0,-133.38442170108294,76.66312738678293Z"
            transform="rotate(-5)"
          />
          <path
            d="M-61.543398211279964,22.080717053617274A65.38461538461539,65.38461538461539,0,0,1,-64.33850481299885,11.649237203431236A65.38461538461539,65.38461538461539,0,0,0,-61.543398211279964,22.080717053617274Z"
            transform="rotate(-5)"
          />
          <path
            d="M-238.67840469733335,41.78081955413579A242.3076923076923,242.3076923076923,0,0,1,-242.30750659952582,0.2999950000250604L-224.6151842795075,0.29999500002504365A224.6153846153846,224.6153846153846,0,0,0,-221.25486850778836,38.708580024568676Z"
            transform="rotate(-5)"
          />
          <path
            d="M-189.2305314336685,-0.29999500002494894A189.23076923076923,189.23076923076923,0,0,1,-186.40778804756062,-32.56409954051176A189.23076923076923,189.23076923076923,0,0,0,-189.2305314336685,-0.29999500002494894Z"
            transform="rotate(-5)"
          />
          <path
            d="M-134.0329442328218,-23.93824727849754A136.15384615384616,136.15384615384616,0,0,1,-128.04505828889546,-46.285341851158215A136.15384615384616,136.15384615384616,0,0,0,-134.0329442328218,-23.93824727849754Z"
            transform="rotate(-5)"
          />
          <path
            d="M-77.96365825521521,-28.695698970281313A83.07692307692307,83.07692307692307,0,0,1,-72.09625426988705,-41.27838742224295A83.07692307692307,83.07692307692307,0,0,0,-77.96365825521521,-28.695698970281313Z"
            transform="rotate(-5)"
          />
          <path
            d="M-71.796259269862,-41.79799400430298A83.07692307692307,83.07692307692307,0,0,1,-63.83303341522221,-53.17065913584405A83.07692307692307,83.07692307692307,0,0,0,-71.796259269862,-41.79799400430298Z"
            transform="rotate(-5)"
          />
          <path
            d="M-49.894161007848794,-42.25778775938163A65.38461538461539,65.38461538461539,0,0,1,-42.25778775938162,-49.8941610078488A65.38461538461539,65.38461538461539,0,0,0,-49.894161007848794,-42.25778775938163Z"
            transform="rotate(-5)"
          />
          <path
            d="M-87.28798337619826,-104.4924771483658A136.15384615384616,136.15384615384616,0,0,1,-68.33656111902366,-117.76240587263472A136.15384615384616,136.15384615384616,0,0,0,-87.28798337619826,-104.4924771483658Z"
            transform="rotate(-5)"
          />
          <path
            d="M-41.27838742224304,-72.096254269887A83.07692307692307,83.07692307692307,0,0,1,-28.695698970281406,-77.9636582552152A83.07692307692307,83.07692307692307,0,0,0,-41.27838742224304,-72.096254269887Z"
            transform="rotate(-5)"
          />
          <path
            d="M-40.23419935628466,-111.41968092232763A118.46153846153847,118.46153846153847,0,0,1,-20.866001717693976,-116.6093738384344A118.46153846153847,118.46153846153847,0,0,0,-40.23419935628466,-111.41968092232763Z"
            transform="rotate(-5)"
          />
          <path
            d="M-7.986081075993025,-47.01891876750695A47.69230769230769,47.69230769230769,0,0,1,-0.2999950000250128,-47.69136416603861A47.69230769230769,47.69230769230769,0,0,0,-7.986081075993025,-47.01891876750695Z"
            transform="rotate(-5)"
          />
        </g>
        <g fill="#08306b">
          <path
            d="M0.29999500002499657,-153.84586135562566A153.84615384615384,153.84615384615384,0,0,1,26.419616064114127,-151.56069061692494A153.84615384615384,153.84615384615384,0,0,0,0.29999500002499657,-153.84586135562566Z"
            transform="rotate(-5)"
          />
          <path
            d="M30.082733095870097,-168.88005493978528A171.53846153846155,171.53846153846155,0,0,1,58.387616393602265,-161.29578431830078A171.53846153846155,171.53846153846155,0,0,0,30.082733095870097,-168.88005493978528Z"
            transform="rotate(-5)"
          />
          <path
            d="M10.541994361626475,-28.086764763486656A30,30,0,0,1,14.739446715220014,-26.129460586265267A30,30,0,0,0,10.541994361626475,-28.086764763486656Z"
            transform="rotate(-5)"
          />
          <path
            d="M24.105485374049266,-41.1519354089118A47.69230769230769,47.69230769230769,0,0,1,30.425608472245607,-36.726537573141414A47.69230769230769,47.69230769230769,0,0,0,24.105485374049266,-41.1519354089118Z"
            transform="rotate(-5)"
          />
          <path
            d="M65.00273544139452,-77.00053412125501A100.76923076923077,100.76923076923077,0,0,1,77.00053412125501,-65.00273544139452A100.76923076923077,100.76923076923077,0,0,0,65.00273544139452,-77.00053412125501Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.833033415222204,-53.17065913584407A83.07692307692307,83.07692307692307,0,0,1,71.796259269862,-41.79799400430295A83.07692307692307,83.07692307692307,0,0,0,63.833033415222204,-53.17065913584407Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.45193040893679,-23.585878791989344A47.69230769230769,47.69230769230769,0,0,1,44.71261864913445,-16.59331029526135A47.69230769230769,47.69230769230769,0,0,0,41.45193040893679,-23.585878791989344Z"
            transform="rotate(-5)"
          />
          <path
            d="M61.54339821127998,-22.08071705361724A65.38461538461539,65.38461538461539,0,0,1,64.33850481299886,-11.649237203431182A65.38461538461539,65.38461538461539,0,0,0,61.54339821127998,-22.08071705361724Z"
            transform="rotate(-5)"
          />
          <path
            d="M47.01891876750695,-7.986081075993032A47.69230769230769,47.69230769230769,0,0,1,47.69136416603861,-0.29999500002499996A47.69230769230769,47.69230769230769,0,0,0,47.01891876750695,-7.986081075993032Z"
            transform="rotate(-5)"
          />
          <path
            d="M153.84586135562566,0.29999500002499996A153.84615384615384,153.84615384615384,0,0,1,151.56069061692494,26.419616064114106A153.84615384615384,153.84615384615384,0,0,0,153.84586135562566,0.29999500002499996Z"
            transform="rotate(-5)"
          />
          <path
            d="M64.33850481299886,11.649237203431182A65.38461538461539,65.38461538461539,0,0,1,61.54339821127998,22.08071705361724A65.38461538461539,65.38461538461539,0,0,0,64.33850481299886,11.649237203431182Z"
            transform="rotate(-5)"
          />
          <path
            d="M111.21447225651659,40.798005531876925A118.46153846153847,118.46153846153847,0,0,1,102.7403702123479,58.97077601070089A118.46153846153847,118.46153846153847,0,0,0,111.21447225651659,40.798005531876925Z"
            transform="rotate(-5)"
          />
          <path
            d="M71.79625926986202,41.797994004302936A83.07692307692307,83.07692307692307,0,0,1,63.833033415222225,53.17065913584404A83.07692307692307,83.07692307692307,0,0,0,71.79625926986202,41.797994004302936Z"
            transform="rotate(-5)"
          />
          <path
            d="M63.44736727725424,53.63027814130933A83.07692307692307,83.07692307692307,0,0,1,53.63027814130933,63.44736727725422A83.07692307692307,83.07692307692307,0,0,0,63.44736727725424,53.63027814130933Z"
            transform="rotate(-5)"
          />
          <path
            d="M41.79816875391637,50.27982714581676A65.38461538461539,65.38461538461539,0,0,1,32.951766875350074,56.474144427221425A65.38461538461539,65.38461538461539,0,0,0,41.79816875391637,50.27982714581676Z"
            transform="rotate(-5)"
          />
          <path
            d="M23.585878791989334,41.451930408936796A47.69230769230769,47.69230769230769,0,0,1,16.593310295261357,44.71261864913444A47.69230769230769,47.69230769230769,0,0,0,23.585878791989334,41.451930408936796Z"
            transform="rotate(-5)"
          />
          <path
            d="M40.23419935628462,111.41968092232764A118.46153846153847,118.46153846153847,0,0,1,20.866001717693884,116.60937383843442A118.46153846153847,118.46153846153847,0,0,0,40.23419935628462,111.41968092232764Z"
            transform="rotate(-5)"
          />
          <path
            d="M32.56409954051184,186.40778804756062A189.23076923076923,189.23076923076923,0,0,1,0.29999500002510976,189.2305314336685A189.23076923076923,189.23076923076923,0,0,0,32.56409954051184,186.40778804756062Z"
            transform="rotate(-5)"
          />
          <path
            d="M-0.2999950000250025,189.2305314336685A189.23076923076923,189.23076923076923,0,0,1,-32.564099540511734,186.40778804756062L-29.49185829209098,168.98424210991234A171.53846153846155,171.53846153846155,0,0,0,-0.29999500002500007,171.53819921516669Z"
            transform="rotate(-5)"
          />
          <path
            d="M-17.7937531470992,99.18578637467459A100.76923076923077,100.76923076923077,0,0,1,-34.183050933280015,94.79428726835427A100.76923076923077,100.76923076923077,0,0,0,-17.7937531470992,99.18578637467459Z"
            transform="rotate(-5)"
          />
          <path
            d="M-16.59331029526135,44.71261864913445A47.69230769230769,47.69230769230769,0,0,1,-23.585878791989327,41.4519304089368A47.69230769230769,47.69230769230769,0,0,0,-16.59331029526135,44.71261864913445Z"
            transform="rotate(-5)"
          />
          <path
            d="M-112.56739543078369,194.37245816176411A224.6153846153846,224.6153846153846,0,0,1,-144.15004789959346,172.25804682644602A224.6153846153846,224.6153846153846,0,0,0,-112.56739543078369,194.37245816176411Z"
            transform="rotate(-5)"
          />
          <path
            d="M-76.37517448214984,90.55367920463378A118.46153846153847,118.46153846153847,0,0,1,-90.55367920463377,76.37517448214986A118.46153846153847,118.46153846153847,0,0,0,-76.37517448214984,90.55367920463378Z"
            transform="rotate(-5)"
          />
          <path
            d="M-158.70493971114513,132.77764071783847A206.92307692307693,206.92307692307693,0,0,1,-179.0504554144128,103.72123302007641A206.92307692307693,206.92307692307693,0,0,0,-158.70493971114513,132.77764071783847Z"
            transform="rotate(-5)"
          />
          <path
            d="M-179.35045041443783,103.20162643801642A206.92307692307693,206.92307692307693,0,0,1,-194.34127977178372,71.05368913701038L-161.0905756524897,58.951422569194655A171.53846153846155,171.53846153846155,0,0,0,-148.70643573978265,85.50929631655346Z"
            transform="rotate(-5)"
          />
          <path
            d="M-78.16886692102626,28.131892794689048A83.07692307692307,83.07692307692307,0,0,1,-81.76217093599833,14.721499643772129L-64.33850481299885,11.649237203431236A65.38461538461539,65.38461538461539,0,0,0,-61.543398211279964,22.080717053617274Z"
            transform="rotate(-5)"
          />
          <path
            d="M-256.1019389263667,44.85305873801188A260,260,0,0,1,-259.9998269287885,0.29999500002501733L-242.30750659952582,0.2999950000250604A242.3076923076923,242.3076923076923,0,0,0,-238.67840469733335,41.78081955413579Z"
            transform="rotate(-5)"
          />
          <path
            d="M-189.2305314336685,-0.29999500002494894A189.23076923076923,189.23076923076923,0,0,1,-186.40778804756062,-32.56409954051176A189.23076923076923,189.23076923076923,0,0,0,-189.2305314336685,-0.29999500002494894Z"
            transform="rotate(-5)"
          />
          <path
            d="M-134.0329442328218,-23.93824727849754A136.15384615384616,136.15384615384616,0,0,1,-128.04505828889546,-46.285341851158215A136.15384615384616,136.15384615384616,0,0,0,-134.0329442328218,-23.93824727849754Z"
            transform="rotate(-5)"
          />
          <path
            d="M-77.96365825521521,-28.695698970281313A83.07692307692307,83.07692307692307,0,0,1,-72.09625426988705,-41.27838742224295A83.07692307692307,83.07692307692307,0,0,0,-77.96365825521521,-28.695698970281313Z"
            transform="rotate(-5)"
          />
          <path
            d="M-71.796259269862,-41.79799400430298A83.07692307692307,83.07692307692307,0,0,1,-63.83303341522221,-53.17065913584405A83.07692307692307,83.07692307692307,0,0,0,-71.796259269862,-41.79799400430298Z"
            transform="rotate(-5)"
          />
          <path
            d="M-49.894161007848794,-42.25778775938163A65.38461538461539,65.38461538461539,0,0,1,-42.25778775938162,-49.8941610078488A65.38461538461539,65.38461538461539,0,0,0,-49.894161007848794,-42.25778775938163Z"
            transform="rotate(-5)"
          />
          <path
            d="M-87.28798337619826,-104.4924771483658A136.15384615384616,136.15384615384616,0,0,1,-68.33656111902366,-117.76240587263472A136.15384615384616,136.15384615384616,0,0,0,-87.28798337619826,-104.4924771483658Z"
            transform="rotate(-5)"
          />
          <path
            d="M-41.27838742224304,-72.096254269887A83.07692307692307,83.07692307692307,0,0,1,-28.695698970281406,-77.9636582552152A83.07692307692307,83.07692307692307,0,0,0,-41.27838742224304,-72.096254269887Z"
            transform="rotate(-5)"
          />
          <path
            d="M-40.23419935628466,-111.41968092232763A118.46153846153847,118.46153846153847,0,0,1,-20.866001717693976,-116.6093738384344A118.46153846153847,118.46153846153847,0,0,0,-40.23419935628466,-111.41968092232763Z"
            transform="rotate(-5)"
          />
          <path
            d="M-7.986081075993025,-47.01891876750695A47.69230769230769,47.69230769230769,0,0,1,-0.2999950000250128,-47.69136416603861A47.69230769230769,47.69230769230769,0,0,0,-7.986081075993025,-47.01891876750695Z"
            transform="rotate(-5)"
          />
        </g>
      </g>
      <g class="direction-labels" font-weight="500" font-size="16">
        <g text-anchor="middle" transform="rotate(-90) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">0.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(-80) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">10.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(-70) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">20.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(-60) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">30.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(-50) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">40.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(-40) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">50.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(-30.000000000000014) translate(280,0)"
        >
          <text transform="rotate(90)translate(0,6)">60.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(-20.000000000000014) translate(280,0)"
        >
          <text transform="rotate(90)translate(0,6)">70.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(-10) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">80.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(0) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">90.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(9.999999999999986) translate(280,0)"
        >
          <text transform="rotate(-90)translate(0,6)">100.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(19.999999999999986) translate(280,0)"
        >
          <text transform="rotate(-90)translate(0,6)">110.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(29.999999999999986) translate(280,0)"
        >
          <text transform="rotate(-90)translate(0,6)">120.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(40) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">130.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(50) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">140.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(60) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">150.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(69.99999999999997) translate(280,0)"
        >
          <text transform="rotate(-90)translate(0,6)">160.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(79.99999999999997) translate(280,0)"
        >
          <text transform="rotate(-90)translate(0,6)">170.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(90) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">180.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(100) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">190.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(110) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">200.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(120) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">210.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(130) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">220.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(140) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">230.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(149.99999999999997) translate(280,0)"
        >
          <text transform="rotate(-90)translate(0,6)">240.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(159.99999999999997) translate(280,0)"
        >
          <text transform="rotate(-90)translate(0,6)">250.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(170) translate(280,0)">
          <text transform="rotate(-90)translate(0,6)">260.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(180) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">270.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(190) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">280.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(200) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">290.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(210) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">300.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(220) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">310.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(230) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">320.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(240) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">330.0°</text>
        </g>
        <g
          text-anchor="middle"
          transform="rotate(249.99999999999994) translate(280,0)"
        >
          <text transform="rotate(90)translate(0,6)">340.0°</text>
        </g>
        <g text-anchor="middle" transform="rotate(260) translate(280,0)">
          <text transform="rotate(90)translate(0,6)">350.0°</text>
        </g>
      </g>
      <g class="yAxis">
        <circle fill="none" stroke="gray" stroke-dasharray="4,4" r="30" />
        <circle
          fill="none"
          stroke="gray"
          stroke-dasharray="4,4"
          r="65.38461538461539"
        />

        <circle
          fill="none"
          stroke="gray"
          stroke-dasharray="4,4"
          r="100.76923076923077"
        />

        <circle
          fill="none"
          stroke="gray"
          stroke-dasharray="4,4"
          r="136.15384615384616"
        />

        <circle
          fill="none"
          stroke="gray"
          stroke-dasharray="4,4"
          r="171.53846153846155"
        />

        <circle
          fill="none"
          stroke="gray"
          stroke-dasharray="4,4"
          r="206.92307692307693"
        />

        <circle
          fill="none"
          stroke="gray"
          stroke-dasharray="4,4"
          r="242.3076923076923"
        />
      </g>
    </g>
  </svg>
);

export const MetadataDemoWindRose = ({ yUnits, binsTitle, binUnits }) => {
  return (
    <svg
      viewBox="0,0,600,600"
      class="min-w-full"
      font-family="sans-serif"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(300,300)">
        <g class="axes">
          <g class="axis" transform="rotate(-90)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(-45)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(0)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(45)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(90)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(135)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(180)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
          <g class="axis" transform="rotate(225)">
            <line
              x1="30"
              x2="221.666"
              fill="none"
              stroke="gray"
              stroke-dasharray="1,4"
            />
          </g>
        </g>
        <g class="rings">
          <g fill="#f7fbff">
            <path
              d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,21.000014285423717,-21.424271283103188A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M21.424271283103188,-21.000014285423717A30,30,0,0,1,29.998500012499957,-0.29999500002499996A30,30,0,0,0,21.424271283103188,-21.000014285423717Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M29.998500012499957,0.29999500002499996A30,30,0,0,1,21.424271283103188,21.000014285423717A30,30,0,0,0,29.998500012499957,0.29999500002499996Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M25.517827171233435,25.942084168912906A36.388888888888886,36.388888888888886,0,0,1,0.2999950000250014,36.38765226787599L0.29999500002500207,29.998500012499957A30,30,0,0,0,21.000014285423717,21.424271283103188Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002499835,29.998500012499957A30,30,0,0,1,-21.000014285423717,21.424271283103185A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-30.459841425645436,30.035584427965972A42.77777777777777,42.77777777777777,0,0,1,-42.77672585185661,0.29999500002500185L-29.998500012499957,0.29999500002499724A30,30,0,0,0,-21.42427128310318,21.000014285423724Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-36.38765226787599,-0.2999950000249947A36.388888888888886,36.388888888888886,0,0,1,-25.942084168912913,-25.51782717123343L-21.424271283103188,-21.000014285423717A30,30,0,0,0,-29.998500012499957,-0.2999950000249899Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-21.000014285423724,-21.42427128310318A30,30,0,0,1,-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-21.000014285423724,-21.42427128310318Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#deebf7">
            <path
              d="M0.29999500002500207,-29.998500012499957A30,30,0,0,1,21.000014285423717,-21.424271283103188A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M25.942084168912906,-25.517827171233435A36.388888888888886,36.388888888888886,0,0,1,36.38765226787599,-0.2999950000249999L29.998500012499957,-0.29999500002499996A30,30,0,0,0,21.424271283103188,-21.000014285423717Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M49.16575143441897,0.2999950000249999A49.166666666666664,49.166666666666664,0,0,1,34.97756474024961,34.55330774257014L21.424271283103188,21.000014285423717A30,30,0,0,0,29.998500012499957,0.29999500002499996Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M30.035584427965965,30.459841425645436A42.77777777777777,42.77777777777777,0,0,1,0.29999500002499924,42.77672585185661L0.2999950000250014,36.38765226787599A36.388888888888886,36.388888888888886,0,0,0,25.517827171233435,25.942084168912906Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002499696,36.38765226787599A36.388888888888886,36.388888888888886,0,0,1,-25.517827171233435,25.94208416891291L-21.000014285423717,21.424271283103185A30,30,0,0,0,-0.29999500002499835,29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-39.49526582318272,39.07100882550326A55.55555555555555,55.55555555555555,0,0,1,-55.554745576650596,0.2999950000249971L-42.77672585185661,0.29999500002500185A42.77777777777777,42.77777777777777,0,0,0,-30.459841425645436,30.035584427965972Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-49.16575143441897,-0.29999500002499346A49.166666666666664,49.166666666666664,0,0,1,-34.97756474024962,-34.55330774257014L-25.942084168912913,-25.51782717123343A36.388888888888886,36.388888888888886,0,0,0,-36.38765226787599,-0.2999950000249947Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-21.000014285423724,-21.42427128310318A30,30,0,0,1,-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-21.000014285423724,-21.42427128310318Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#c6dbef">
            <path
              d="M0.2999950000250028,-61.94371800700072A61.94444444444445,61.94444444444445,0,0,1,43.588694555817725,-44.01295155349719L21.000014285423717,-21.424271283103188A30,30,0,0,0,0.29999500002500207,-29.998500012499957Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M48.530626237513296,-48.106369239833825A68.33333333333333,68.33333333333333,0,0,1,68.33267481552588,-0.2999950000249999L36.38765226787599,-0.2999950000249999A36.388888888888886,36.388888888888886,0,0,0,25.942084168912906,-25.517827171233435Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M68.33267481552588,0.2999950000249999A68.33333333333333,68.33333333333333,0,0,1,48.530626237513296,48.106369239833825L34.97756474024961,34.55330774257014A49.166666666666664,49.166666666666664,0,0,0,49.16575143441897,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M43.588694555817725,44.01295155349719A61.94444444444445,61.94444444444445,0,0,1,0.2999950000250028,61.94371800700072L0.29999500002499924,42.77672585185661A42.77777777777777,42.77777777777777,0,0,0,30.035584427965965,30.459841425645436Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.2999950000249946,81.1105563331862A81.1111111111111,81.1111111111111,0,0,1,-57.14169591016969,57.565952907849166L-25.517827171233435,25.94208416891291A36.388888888888886,36.388888888888886,0,0,0,-0.29999500002499696,36.38765226787599Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-80.15419703904972,79.72994004137027A113.05555555555554,113.05555555555554,0,0,1,-113.05515753372451,0.29999500002501L-55.554745576650596,0.2999950000249971A55.55555555555555,55.55555555555555,0,0,0,-39.49526582318272,39.07100882550326Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-113.05515753372451,-0.2999950000249823A113.05555555555554,113.05555555555554,0,0,1,-80.15419703904973,-79.72994004137026L-34.97756474024962,-34.55330774257014A49.166666666666664,49.166666666666664,0,0,0,-49.16575143441897,-0.29999500002499346Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-25.51782717123344,-25.9420841689129A36.388888888888886,36.388888888888886,0,0,1,-0.29999500002500584,-36.38765226787599L-0.29999500002499907,-29.998500012499957A30,30,0,0,0,-21.000014285423724,-21.42427128310318Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#9ecae1">
            <path
              d="M0.29999500002499907,-151.3885916507988A151.38888888888889,151.38888888888889,0,0,1,106.83577125172124,-107.26002824940072L43.588694555817725,-44.01295155349719A61.94444444444445,61.94444444444445,0,0,0,0.2999950000250028,-61.94371800700072Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M62.08360820879964,-61.65935121112017A87.5,87.5,0,0,1,87.49948572991705,-0.29999500002499996L68.33267481552588,-0.2999950000249999A68.33333333333333,68.33333333333333,0,0,0,48.530626237513296,-48.106369239833825Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M100.27732903826939,0.29999500002499996A100.27777777777779,100.27777777777779,0,0,1,71.11890786107472,70.69465086339524L48.530626237513296,48.106369239833825A68.33333333333333,68.33333333333333,0,0,0,68.33267481552588,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M48.10636923983383,48.530626237513296A68.33333333333333,68.33333333333333,0,0,1,0.29999500002500906,68.33267481552588L0.2999950000250028,61.94371800700072A61.94444444444445,61.94444444444445,0,0,0,43.588694555817725,44.01295155349719Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.2999950000249909,119.44406771222228A119.44444444444446,119.44444444444446,0,0,1,-84.24758175297775,84.67183875065727L-57.14169591016969,57.565952907849166A81.1111111111111,81.1111111111111,0,0,0,-0.2999950000249946,81.1105563331862Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-120.81293184165364,120.38867484397424A170.55555555555557,170.55555555555557,0,0,1,-170.555291720498,0.29999500002498325L-113.05515753372451,0.29999500002501A113.05555555555554,113.05555555555554,0,0,0,-80.15419703904972,79.72994004137027Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-202.49977778506315,-0.29999500002500945A202.5,202.5,0,0,1,-143.40109455942692,-142.97683756174737L-80.15419703904973,-79.72994004137026A113.05555555555554,113.05555555555554,0,0,0,-113.05515753372451,-0.2999950000249823Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-66.17700261382844,-66.60125961150788A93.8888888888889,93.8888888888889,0,0,1,-0.2999950000250567,-93.88840961370092L-0.29999500002500584,-36.38765226787599A36.388888888888886,36.388888888888886,0,0,0,-25.51782717123344,-25.9420841689129Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#6baed6">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706L106.83577125172124,-107.26002824940072A151.38888888888889,151.38888888888889,0,0,0,0.29999500002499907,-151.3885916507988Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996L87.49948572991705,-0.29999500002499996A87.5,87.5,0,0,0,62.08360820879964,-61.65935121112017Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568L71.11890786107472,70.69465086339524A100.27777777777779,100.27777777777779,0,0,0,100.27732903826939,0.29999500002499996Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M52.62403571104174,53.04829270872121A74.72222222222223,74.72222222222223,0,0,1,0.2999950000250119,74.72162000938631L0.29999500002500906,68.33267481552588A68.33333333333333,68.33333333333333,0,0,0,48.10636923983383,48.530626237513296Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002498225,132.22188189699415A132.2222222222222,132.2222222222222,0,0,1,-93.28286081177163,93.70711780945112L-84.24758175297775,84.67183875065727A119.44444444444446,119.44444444444446,0,0,0,-0.2999950000249909,119.44406771222228Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-152.436357201005,152.0121002033256A215.2777777777778,215.2777777777778,0,0,1,-215.27756875238592,0.29999500002499807L-170.555291720498,0.29999500002498325A170.55555555555557,170.55555555555557,0,0,0,-120.81293184165364,120.38867484397424Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-215.27756875238592,-0.2999950000249454A215.2777777777778,215.2777777777778,0,0,1,-152.436357201005,-152.01210020332553L-143.40109455942692,-142.97683756174737A202.5,202.5,0,0,0,-202.49977778506315,-0.29999500002500945Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228L-0.2999950000250567,-93.88840961370092A93.8888888888889,93.8888888888889,0,0,0,-66.17700261382844,-66.60125961150788Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#4292c6">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706A164.166,164.166,0,0,0,0.2999950000250216,-164.16639256389965Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996A93.8888888888889,93.8888888888889,0,0,0,66.6012596115079,-66.17700261382842Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568A106.66666666666666,106.66666666666666,0,0,0,106.66624480489475,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M75.21229652640568,75.63655352408516A106.66666666666666,106.66666666666666,0,0,1,0.2999950000250094,106.66624480489475L0.2999950000250119,74.72162000938631A74.72222222222223,74.72222222222223,0,0,0,52.62403571104174,53.04829270872121Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.2999950000249976,144.999689665185A145,145,0,0,1,-102.31813533335755,102.742392331037L-93.28286081177163,93.70711780945112A132.2222222222222,132.2222222222222,0,0,0,-0.29999500002498225,132.22188189699415Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-152.436357201005,152.0121002033256A215.2777777777778,215.2777777777778,0,0,1,-215.27756875238592,0.29999500002499807A215.2777777777778,215.2777777777778,0,0,0,-152.436357201005,152.0121002033256Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-221.66646366582177,-0.29999500002495977A221.666,221.666,0,0,1,-156.95398811858377,-156.52973112090427L-152.436357201005,-152.01210020332553A215.2777777777778,215.2777777777778,0,0,0,-215.27756875238592,-0.2999950000249454Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,-84.24758175297778,-84.67183875065726Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#2171b5">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706A164.166,164.166,0,0,0,0.2999950000250216,-164.16639256389965Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996A93.8888888888889,93.8888888888889,0,0,0,66.6012596115079,-66.17700261382842Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568A106.66666666666666,106.66666666666666,0,0,0,106.66624480489475,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M79.72994004137027,80.15419703904972A113.05555555555554,113.05555555555554,0,0,1,0.29999500002500307,113.05515753372451L0.2999950000250094,106.66624480489475A106.66666666666666,106.66666666666666,0,0,0,75.21229652640568,75.63655352408516Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.2999950000249898,170.555291720498A170.55555555555557,170.55555555555557,0,0,1,-120.38867484397421,120.81293184165366L-102.31813533335755,102.742392331037A145,145,0,0,0,-0.2999950000249976,144.999689665185Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-165.9892492572003,165.5649922595209A234.44444444444443,234.44444444444443,0,0,1,-234.44425250763592,0.299995000025007L-215.27756875238592,0.29999500002499807A215.2777777777778,215.2777777777778,0,0,0,-152.436357201005,152.0121002033256Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-228.05535824170644,-0.2999950000249815A228.05555555555557,228.05555555555557,0,0,1,-161.47161879747776,-161.04736179979824L-156.95398811858377,-156.52973112090427A221.666,221.666,0,0,0,-221.66646366582177,-0.29999500002495977Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,-84.24758175297778,-84.67183875065726Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#08519c">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706A164.166,164.166,0,0,0,0.2999950000250216,-164.16639256389965Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996A93.8888888888889,93.8888888888889,0,0,0,66.6012596115079,-66.17700261382842Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568A106.66666666666666,106.66666666666666,0,0,0,106.66624480489475,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M84.24758175297778,84.67183875065724A119.44444444444446,119.44444444444446,0,0,1,0.2999950000250055,119.44406771222228L0.29999500002500307,113.05515753372451A113.05555555555554,113.05555555555554,0,0,0,79.72994004137027,80.15419703904972Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002499097,196.11088165686914A196.11111111111111,196.11111111111111,0,0,1,-138.45920578520494,138.8834627828844L-120.38867484397421,120.81293184165366A170.55555555555557,170.55555555555557,0,0,0,-0.2999950000249898,170.555291720498Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-175.0245095870993,174.60025258941982A247.2222222222222,247.2222222222222,0,0,1,-247.22204020575063,0.2999950000250554L-234.44425250763592,0.299995000025007A234.44444444444443,234.44444444444443,0,0,0,-165.9892492572003,165.5649922595209Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-234.44425250763592,-0.2999950000249496A234.44444444444443,234.44444444444443,0,0,1,-165.98924925720036,-165.56499225952086L-161.47161879747776,-161.04736179979824A228.05555555555557,228.05555555555557,0,0,0,-228.05535824170644,-0.2999950000249815Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,-84.24758175297778,-84.67183875065726Z"
              transform="rotate(-22.5)"
            />
          </g>
          <g fill="#08306b">
            <path
              d="M0.2999950000250216,-164.16639256389965A164.166,164.166,0,0,1,115.87104092602654,-116.295297923706A164.166,164.166,0,0,0,0.2999950000250216,-164.16639256389965Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M66.6012596115079,-66.17700261382842A93.8888888888889,93.8888888888889,0,0,1,93.88840961370092,-0.29999500002499996A93.8888888888889,93.8888888888889,0,0,0,66.6012596115079,-66.17700261382842Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M106.66624480489475,0.2999950000249999A106.66666666666666,106.66666666666666,0,0,1,75.63655352408517,75.21229652640568A106.66666666666666,106.66666666666666,0,0,0,106.66624480489475,0.2999950000249999Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M84.24758175297778,84.67183875065724A119.44444444444446,119.44444444444446,0,0,1,0.2999950000250055,119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,84.24758175297778,84.67183875065724Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-0.29999500002497687,202.49977778506315A202.5,202.5,0,0,1,-142.97683756174737,143.40109455942692L-138.45920578520494,138.8834627828844A196.11111111111111,196.11111111111111,0,0,0,-0.29999500002499097,196.11088165686914Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-184.05976922751475,183.63551222983537A260,260,0,0,1,-259.9998269287885,0.29999500002501733L-247.22204020575063,0.2999950000250554A247.2222222222222,247.2222222222222,0,0,0,-175.0245095870993,174.60025258941982Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-247.22204020575063,-0.2999950000249949A247.2222222222222,247.2222222222222,0,0,1,-175.02450958709932,-174.60025258941977L-165.98924925720036,-165.56499225952086A234.44444444444443,234.44444444444443,0,0,0,-234.44425250763592,-0.2999950000249496Z"
              transform="rotate(-22.5)"
            />
            <path
              d="M-84.24758175297778,-84.67183875065726A119.44444444444446,119.44444444444446,0,0,1,-0.2999950000250467,-119.44406771222228A119.44444444444446,119.44444444444446,0,0,0,-84.24758175297778,-84.67183875065726Z"
              transform="rotate(-22.5)"
            />
          </g>
        </g>
        <g class="direction-labels">
          <g text-anchor="middle" transform="rotate(-90) translate(280,0)">
            <text transform="rotate(90)translate(0,6)" font-weight="500">
              N
            </text>
          </g>
          <g text-anchor="middle" transform="rotate(-45) translate(280,0)">
            <text
              transform="rotate(90)translate(0,6)"
              font-weight="500"
              font-size="28"
            >
              NE
            </text>
          </g>
          <g text-anchor="middle" transform="rotate(0) translate(280,0)">
            <text
              transform="rotate(-90)translate(0,6)"
              font-weight="500"
              font-size="28"
            >
              E
            </text>
          </g>
          <g text-anchor="middle" transform="rotate(45) translate(280,0)">
            <text
              transform="rotate(-90)translate(0,6)"
              font-weight="500"
              font-size="28"
            >
              SE
            </text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(89.99999999999997) translate(280,0)"
          >
            <text
              transform="rotate(-90)translate(0,6)"
              font-weight="500"
              font-size="28"
            >
              S
            </text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(134.99999999999997) translate(280,0)"
          >
            <text
              transform="rotate(-90)translate(0,6)"
              font-weight="500"
              font-size="28"
            >
              SW
            </text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(180.00000000000006) translate(280,0)"
          >
            <text
              transform="rotate(90)translate(0,6)"
              font-weight="500"
              font-size="28"
            >
              W
            </text>
          </g>
          <g
            text-anchor="middle"
            transform="rotate(224.99999999999994) translate(280,0)"
          >
            <text
              transform="rotate(90)translate(0,6)"
              font-weight="500"
              font-size="28"
            >
              NW
            </text>
          </g>
        </g>
        <g class="yAxis">
          <rect
            rx="8"
            ry="8"
            fill="none"
            stroke="black"
            transform="translate(-5,-265.5)"
            stroke-width="1.5px"
            width="80px"
            height="20px"
          />
          <text
            y="-250"
            paint-order="stroke"
            stroke-width="1.4px"
            stroke="white"
            id="yUnitsText"
          >
            {yUnits}
          </text>
          <circle fill="none" stroke="gray" stroke-dasharray="4,4" r="30" />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="93.8888888888889"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="157.7777777777778"
          />
          <circle
            fill="none"
            stroke="gray"
            stroke-dasharray="4,4"
            r="221.666"
          />
        </g>
        <g class="legend" transform="translate(240,10)">
          <rect
            rx="8"
            ry="8"
            fill="none"
            transform="translate(-110,-305)"
            stroke="black"
            stroke-width="1.5px"
            height="20px"
            width="150px"
          />
          <text
            text-decoration="underline"
            text-anchor="end"
            transform="translate(25,-287.5)"
          >
            {binsTitle} ({binUnits})
          </text>
          <g transform="translate(0,-282.5)">
            <rect
              fill="#084594"
              width="18"
              height="18"
              stroke="dimgray"
              stroke-width="0.5"
            />
            <text
              x="24"
              y="9"
              dy="0.35em"
              font-family="sans-serif"
              font-size="13"
            >
              7+
            </text>
          </g>
          <g transform="translate(0,-262.5)">
            <rect
              fill="#2171b5"
              width="18"
              height="18"
              stroke="dimgray"
              stroke-width="0.5"
            />
            <text
              x="24"
              y="9"
              dy="0.35em"
              font-family="sans-serif"
              font-size="13"
            >
              6-7
            </text>
          </g>
          <g transform="translate(0,-242.5)">
            <rect
              fill="#4292c6"
              width="18"
              height="18"
              stroke="dimgray"
              stroke-width="0.5"
            />
            <text
              x="24"
              y="9"
              dy="0.35em"
              font-family="sans-serif"
              font-size="13"
            >
              5-6
            </text>
          </g>
          <g transform="translate(0,-222.5)">
            <rect
              fill="#6baed6"
              width="18"
              height="18"
              stroke="dimgray"
              stroke-width="0.5"
            />
            <text
              x="24"
              y="9"
              dy="0.35em"
              font-family="sans-serif"
              font-size="13"
            >
              4-5
            </text>
          </g>
          <g transform="translate(0,-202.5)">
            <rect
              fill="#9ecae1"
              width="18"
              height="18"
              stroke="dimgray"
              stroke-width="0.5"
            />
            <text
              x="24"
              y="9"
              dy="0.35em"
              font-family="sans-serif"
              font-size="13"
            >
              3-4
            </text>
          </g>
          <g transform="translate(0,-182.5)">
            <rect
              fill="#c6dbef"
              width="18"
              height="18"
              stroke="dimgray"
              stroke-width="0.5"
            />
            <text
              x="24"
              y="9"
              dy="0.35em"
              font-family="sans-serif"
              font-size="13"
            >
              2-3
            </text>
          </g>
          <g transform="translate(0,-162.5)">
            <text
              x="24"
              y="9"
              dy="0.35em"
              font-family="sans-serif"
              font-size="13"
            >
              1-2
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};
