export const Footer = () => (
  <footer class="bg-gray-300 text-gray-700 py-8 mt-4">
    <div class="container mx-auto">
      <div class="grid px-4 grid-cols-1 sm:grid-cols-2">
        <div>
          <h4 class="h4 text-gray-700 uppercase tracking-tight">Support</h4>
          <ul class="list-none">
            <li class="my-3 text-xl hover:text-blue-700 hover:underline">
              <a
                class=""
                rel="noopener noreferrer"
                href="https://www.buymeacoffee.com/OfU1nAuiI"
              >
                Buy me a coffee
              </a>
            </li>
            <li class="my-3 text-xl hover:text-blue-700 hover:underline">
              <a
                href="https://paypal.me/BlomJ"
                rel="noopener noreferrer"
                title="Donate via PayPal"
              >
                Donate
              </a>
            </li>
            <li class="my-3 text-xl hover:text-blue-700 hover:underline">
              <a href="/feedback">Give feedback</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 class="h4 text-gray-700 uppercase tracking-tight">Social</h4>
          <ul class="list-none">
            <li class="my-3 text-xl hover:text-blue-700 hover:underline">
              <a
                href="https://twitter.com/Mega_Jules"
                rel="noreferrer noopener"
              >
                Twitter
              </a>
            </li>
            <li class="my-3 text-xl hover:text-blue-700 hover:underline">
              <a href="http://github.com/julesBlm/" rel="noreferrer noopener">
                GitHub
              </a>
            </li>
            <li class="my-3 text-xl hover:text-blue-700 hover:underline">
              <a href="https://ternaryplot.com/" rel="noreferrer noopener">
                TernaryPlot.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="mx-0 my-6 text-center text-gray-500 text-lg  flex justify-center">
        <div class="w-48 sm:w-64">
          WindRose.xyz - A zero-setup rose chart maker, get started creating
          your rose chart now. Easily create wind rose plots online,
          downloadable in PNG or SVG format.
          <br /> Jules Blom © {new Date().getFullYear()}
        </div>
      </div>
    </div>
  </footer>
);

