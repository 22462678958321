import { h, Component } from "preact";
import { Router } from "preact-router";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { Header } from "./Header";
import { Footer } from "./Footer";

// Code-splitting is automated for routes
import Home from "../routes/home/index";
import About from "../routes/about/index";
import Explain from "../routes/explain/index";
import Feedback from "../routes/feedback/index";
import Donate from "../routes/donate/index";
// import Success from "../routes/success/index";
// import Thanks from "../routes/thanks/index";

import { OverlayProvider } from "@react-aria/overlays";

Sentry.init({
  dsn:
    "https://1ba968bf980f4535891a350b95641e22@o358752.ingest.sentry.io/5495130",
  integrations:
    typeof window !== "undefined" ? [new Integrations.BrowserTracing()] : [],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

export default class App extends Component {
  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  handleRoute = (e) => {
    this.currentUrl = e.url;
  };

  render() {
    return (
      <div id="app antialiased">
        <Header />
        <div class="min-h-screen flex flex-col">
          <main class="main">
            <OverlayProvider>
              <Router onChange={this.handleRoute}>
                <Home path="/" />
                <About path="/about" />
                <Explain path="/explain" />
                <Feedback path="/feedback" />
                <Donate path="/donate" />
                {/* <Thanks path="/thanks" /> */}
                {/* <Success path="/success" /> */}
              </Router>
            </OverlayProvider>
          </main>
          <Footer />
        </div>
      </div>
    );
  }
}
