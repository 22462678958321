import { useState } from "preact/hooks";
import { Link } from "preact-router/match";
import { WindRose22 } from "../routes/home/components/SelectInitialData/DefineData/DemoWindRoses";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header class="bg-blue-900 w-full sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3">
      <div class="flex items-center justify-between px-4 py-4 sm:p-0 ">
        
        <div class="flex">
          <div class="h-16 w-16 mr-4">
            <WindRose22  directions={Array(16).fill("")} />
          </div>
          <h1 class="block mt-5 text-2xl text-gray-400 font-extrabold" id="site-title">
            WindRose.xyz
          </h1>
        </div>

        <button
          onClick={() => setIsOpen(!isOpen)}
          class="block sm:hidden mr-6 text-gray-400 focus:text-white focus:outline-none"
        >
          <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
        
      </div>

      <nav
        data-state={isOpen ? "open" : "closed"}
        class="px-2 pt-2 pb-4 sm:flex sm:p-0"
      >
        <Link
          class="nav-link"
          activeClassName="text-teal-400"
          href="/"
        >
          Home
        </Link>
        <Link
          class="nav-link"
          activeClassName="text-teal-400"
          href="/donate"
        >
          Donate
        </Link>
        <Link
          class="nav-link"
          activeClassName="text-teal-400"
          href="/feedback"
        >
          Feedback
        </Link>
        <Link
          class="nav-link"
          activeClassName="text-teal-400"
          href="/about"
        >
          About
        </Link>
        <Link
          class="nav-link"
          activeClassName="text-teal-400"
          href="/explain"
        >
          Explain
        </Link>
      </nav>
    </header>
  );
};

export { Header };
